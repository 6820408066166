<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="header">
                    <h4 class="title">Link Generator</h4>
                </div>
                <div class="content">
                    <div class="row mt-2">
                        <div class="col-sm-6">
                            <label for="linkType">Select Link Type</label>
                            <ng-select id="linkType"
                                       [virtualScroll]="true"
                                       [items]="linkTypes"
                                       bindLabel="name"
                                       bindValue="linkType"
                                       [multiple]="false"
                                       [(ngModel)]="selectedLinkType"
                                       (ngModelChange)="linkTypeChanged()">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row mt-2" [hidden]="datesHidden()">
                        <div class="col-sm-6">
                            <label for="startDate">Start Date</label>
                            <div class="input-group bordered">
                                <input id="startDate" type="text" placeholder="Datepicker" class="form-control"
                                       bsDatepicker
                                       [bsConfig]="dateConfig" [(ngModel)]="startDate">
                                <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label for="endDate">End Date</label>
                            <div class="input-group bordered">
                                <input id="endDate" type="text" placeholder="Datepicker" class="form-control"
                                       bsDatepicker
                                       [bsConfig]="dateConfig" [(ngModel)]="endDate">
                                <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-6" [hidden]="cityHidden()">
                            <label for="citySelect">Select City(s)</label>
                            <ng-select id="citySelect"
                                       [virtualScroll]="true"
                                       [items]="cities"
                                       bindLabel="label"
                                       [loading]="!cities"
                                       [multiple]="true"
                                       [(ngModel)]="selectedCities"
                                       [maxSelectedItems]="selectedLinkType === 'DynamicLink_PerformSearch' ? 3 : 1">
                            </ng-select>
                        </div>
                        <div class="col-sm-6" [hidden]="performersHidden()">
                            <label for="performerSelect">Select Performer(s)</label>
                            <ng-select id="performerSelect"
                                       [virtualScroll]="true"
                                       [items]="performers"
                                       bindLabel="name"
                                       [loading]="!performers"
                                       [multiple]="true"
                                       [(ngModel)]="selectedPerformers">
                            </ng-select>
                        </div>
                        <div class="col-sm-6" [hidden]="findEventsHidden()">
                            <button class="btn btn-default" (click)="findEvents()">Find Events</button>
                        </div>
                    </div>
                    <div class="row mt-2" [hidden]="findEventsHidden()">
                        <div class="col-sm-6">
                            <label for="searchCity">Events</label>
                            <ng-select id="searchCity"
                                       [virtualScroll]="true"
                                       [items]="eventResults"
                                       bindLabel="eventName"
                                       [loading]="eventResultsLoading"
                                       [multiple]="true"
                                       [maxSelectedItems]="selectedLinkType == 'DynamicLink_EventDetails' ? 1 : 100"
                                       [(ngModel)]="selectedEvents">
                                <ng-template ng-option-tmp let-item="item">
                                    {{item.eventName}}<br/>
                                    <span class="text-muted">&nbsp;{{item.eventDateString | date:'MMM d, y, h:mm a'}}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-6">
                            <button class="btn btn-primary" (click)="generateLink()">Generate Link</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12" [hidden]="generatedLink == null && generatingLink === false">
            <div class="card">
                <div class="header">
                    <h4 class="title">Generated Link:</h4>
                </div>
                <div class="content">
                    <div class="row mt-2" [hidden]="!generatingLink">
                        <div class="col-sm-4">
                            <mat-progress-spinner color="accent"
                                                  mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="generatedLink" [hidden]="generatingLink">
                        <div class="col-sm-12">
                            <p>
                                Short Link: {{generatedLink.shortDynamicLink}}
                                <i class="ti-clipboard ml-2 mr-2 text-info" matTooltip="Copy to Clipboard"
                                   (click)="copyToClipboard(generatedLink.shortDynamicLink)"></i>
                            </p>
                        </div>
                        <div class="col-sm-12">
                            <p>
                                Deep Link: {{generatedLink.deepLink}}
                                <i class="ti-clipboard ml-2 mr-2 text-info" matTooltip="Copy to Clipboard"
                                   (click)="copyToClipboard(generatedLink.deepLink)"></i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
