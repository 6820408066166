<div class="modal-header">
    <h4 class="modal-title pull-left">Add a New Promotion</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">Cancel</span>
    </button>

    <div class="modal-body" style="display: inline-block">
        <form [formGroup]="addPromotionForm" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" aria-describedby="name" formControlName="name"
                               [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    </div>

                    <div class="form-group">
                        <label for="details">Details</label>
                        <input type="text" class="form-control" id="details" aria-describedby="details"
                               formControlName="details"
                               [ngClass]="{ 'is-invalid': submitted && f.details.errors }">
                    </div>

                    <div class="form-group">
                        <label for="successMessage">Success Message</label>
                        <input type="text" class="form-control" id="successMessage" aria-describedby="successMessage"
                               formControlName="successMessage"
                               [ngClass]="{ 'is-invalid': submitted && f.successMessage.errors }">
                    </div>

                    <div class="form-group">
                        <label for="endDate">Add an end date</label>
                        <div class="input-group">
                            <input type="text" placeholder="Datepicker" class="form-control" formControlName="endDate"
                                   bsDatepicker
                                   [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                                   [ngClass]="{ 'is-invalid': submitted && f.expiryDate.errors }">
                            <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
                        </div>
                    </div>


                </div>
                <div class="col-md-6">
                    <app-image-upload-preview [componentClass]="{ 'is-invalid': submitted && f.image.errors }" (imageChanged)="imageEvent($event, null)"></app-image-upload-preview>
                </div>
            </div>

            <label for="steps">Promo Steps:</label><br/>
            <div formArrayName="steps" *ngFor="let step of stepControls; let i = index">
                <div class="row create-step">
                    <div class="form-group" formGroupName="{{i}}">
                        <div class="col-md-6">
                            <label>Step {{i + 1}}</label><br/>
                            <input class="form-control" type="text" formControlName="promptMessage" placeholder="Prompt Message">
                            <input class="form-control" type="text" formControlName="detailedPromptMessage" placeholder="Detailed Prompt Message">
                            <input class="form-control" type="text" formControlName="successMessage" placeholder="Success Message">
                            <ng-select placeholder="Action" [clearable]="false" [multiple]="false" [searchable]="false" [items]="actionTypes" formControlName="action">
                            </ng-select>
                            <ng-select placeholder="Completion Operator (optional)" required="false" [clearable]="false" [multiple]="false" [searchable]="false" [items]="metadataOperators" formControlName="metadataCompletionOperator">
                            </ng-select>
                            <input class="form-control" type="text" formControlName="metadataCompletionValue" placeholder="Completion Value (optional)">
                            <input class="btn-danger" type="button" value="Remove Step" (click)="removeStep(i)">
                        </div>
                        <div class="col-md-6">
                            <label class="image-label">Image for Step {{i + 1}}</label><br/>
                            <app-image-upload-preview [componentClass]="{ 'is-invalid': submitted && f.image.errors }" (imageChanged)="imageEvent($event, i)"></app-image-upload-preview>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <input type="button" value="+ Add Step" (click)="addStepToPromo()">
                </div>
            </div>

            <div class="row" *ngIf="error">
                <div class="col-xs-12">
                    <p class="text-danger">{{error}}</p>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-xs-12 text-center">
                    <button class="btn btn-primary btn-lg" [disabled]="submitting">Save New Promotion<i
                            *ngIf="submitting" class="fa fa-fw fa-circle-o-notch fa-spin"></i></button>
                </div>
            </div>
        </form>

    </div>
</div>
