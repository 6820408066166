<div class="modal-header">
    <h4 class="modal-title pull-left">Notification Queue</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">Close</span>
    </button>
</div>
<div class="modal-body">
    <mat-progress-spinner style="margin:0 auto;" color="accent" mode="indeterminate" *ngIf="showLoadingSpinner"></mat-progress-spinner>
    <div class="row mt-3" [hidden]="showLoadingSpinner">
        <div class="col-sm-12 ml-4">
            <mat-checkbox [(ngModel)]="hideDelivered" (ngModelChange)="updateDeliveredFilter()">Hide Delivered Notifications</mat-checkbox>
        </div>
        <mat-table class="col-sm-12" [dataSource]="summariesDataSource" matSort matSortActive="deliveredTimestamp" matSortDirection="desc">
            <ng-container matColumnDef="subject">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Subject</mat-header-cell>
                <mat-cell *matCellDef="let item" class="grey-text">{{item.subject}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="message">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Message</mat-header-cell>
                <mat-cell *matCellDef="let item" class="grey-text">{{item.message}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="count">
                <mat-header-cell *matHeaderCellDef class="table-cell-center" mat-sort-header>Users/Devices</mat-header-cell>
                <mat-cell *matCellDef="let item" class="grey-text table-cell-center">{{item.count}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="deliveredTimestamp">
                <mat-header-cell *matHeaderCellDef class="table-cell-center" mat-sort-header>Delivered Date</mat-header-cell>
                <mat-cell *matCellDef="let item" class="grey-text table-cell-center">
                    <p class="date-text" [hidden]="item.deliveredTimestamp === 0">{{item.deliveredTimestamp * 1000 | date:'M/d/yy, h:mm a'}}</p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="deliverAfterTimestamp">
                <mat-header-cell *matHeaderCellDef class="table-cell-center" mat-sort-header>Scheduled Date</mat-header-cell>
                <mat-cell *matCellDef="let item" class="grey-text table-cell-center">
                    <p class="date-text" [hidden]="item.deliverAfterTimestamp === 0">{{item.deliverAfterTimestamp / 1000000 | date:'M/d/yy, h:mm a'}}</p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="other">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let item" style="justify-content: flex-end;">
                    <i class="ti-info-alt m-4" matTooltip="{{item.title}}, Expiry Date:{{item.deliverBeforeTimestamp / 1000000 | date:'M/d/yy, h:mm a'}}"
                       [matTooltipClass]="'tooltip-wrap'" matTooltipPosition="left"></i>
                    <button type="button"
                            class="btn btn-sm btn-danger"
                            *ngIf="item.deliveredTimestamp === 0"
                            (click)="deleteNotification(item)">
                        Delete
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['subject', 'message', 'count', 'deliverAfterTimestamp', 'deliveredTimestamp', 'other']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['subject', 'message', 'count', 'deliverAfterTimestamp',  'deliveredTimestamp', 'other']"></mat-row>
        </mat-table>
    </div>
</div>
