<button class="btn-primary" (click)="addPromoTapped()">Add New Promotion</button>
<p class="h3">Active Promotions:</p>
<mat-progress-spinner color="accent" mode="indeterminate" *ngIf="loading"></mat-progress-spinner>
<accordion *ngIf="loading == false">
    <accordion-group *ngFor="let promo of promotions">
        <div accordion-heading [textContent]="promo.name"></div>
        <p><span class="text-bold">Details: </span><span> {{promo.details}}</span></p>
        <p><span class="text-bold">Short Link: </span><span> {{promo.shortLink}}</span></p>
        <p><span class="text-bold">Success Message: </span><span> {{promo.successMessage}}</span></p>
        <p><span class="text-bold">End Date: </span><span> {{promo.endDate * 1000 | date}}</span></p>
        <span *ngIf="promo.imageId.length > 0" class="text-bold">Image</span>
        <div *ngIf="promo.imageId.length > 0" class="promo-image" [style.background]="getImageUrl(promo.imageId)"></div>
        <p><span class="h4">Steps:</span></p>
        <accordion>
            <accordion-group *ngFor="let step of getSortedSteps(promo)">
                <div accordion-heading>{{step.priority.toString()}} {{step.action}}</div>
                <p><span class="text-bold">Prompt Message: </span><span> {{step.promptMessage}}</span></p>
                <p><span class="text-bold">Detailed Prompt Message: </span><span> {{step.detailedPromptMessage}}</span></p>
                <p><span class="text-bold">Success Message: </span><span> {{step.successMessage}}</span></p>
                <span *ngIf="step.imageId.length > 0" class="text-bold">Image</span>
                <div *ngIf="step.imageId.length > 0" class="promo-image" [style.background]="getImageUrl(step.imageId)"></div>
            </accordion-group>
        </accordion>
    </accordion-group>
</accordion>
