<div class="container fluid">
  <div class="row" *ngFor="let apiVersion of apiVersions">
    <div class="col-md-10">
      <mat-card>
        <mat-card-title> {{apiVersion.api}} </mat-card-title>
        <p>Current Version:</p>
        <span class="number">{{apiVersion.version}}</span>
        <p>Current Minimum Version:</p>
        <span class="number">{{apiVersion.minimumVersion}}</span>
        <span class="input"></span>
        <hr>

        <form [formGroup]="newVersionForm" (ngSubmit)="onSubmit(apiVersion)">
            <mat-form-field class="full-width">
              <input formControlName="minVersion" matInput placeholder="{{apiVersion.version}}">
              <mat-hint> New Minimum Version</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width">
              <input formControlName="confirmVersion" matInput placeholder="Confirm Minimum Version">
            </mat-form-field>
            <mat-form-field class="full-width">
                <input formControlName="newCurrent" matInput placeholder="{{apiVersion.version+0.01 | number}}">
                <mat-hint> New Current Version</mat-hint>
              </mat-form-field>
    
        <mat-card-actions> <button class="button" mat-raised-button [disabled]="submitting">Create New Service Version<i
              *ngIf="submitting" class="fa fa-fw fa-circle-o-notch fa-spin"></i></button>
        </mat-card-actions>
      </form>
      </mat-card>
    </div>
  </div>
</div>