<div>
    <h4>Documentation</h4>
    <div class="row d-flex">
        <div class="col-md-4">
            <label>
                Platform:
            </label>
            <ng-select [loading]="loading" [items]="platforms" [multiple]="false" [clearable]="false"
                       [(ngModel)]="selectedPlatform" (ngModelChange)="platformSelected()">
            </ng-select>
        </div>
        <div class="col-md-4">
            <label>
                Version:
            </label>
            <ng-select [loading]="loading" [items]="availableVersions" [multiple]="false" [clearable]="false"
                       [(ngModel)]="selectedVersion" (ngModelChange)="versionSelected()">
            </ng-select>
        </div>
        <div class="col-md-4 align-self-flex-end">
            <button class="btn btn-primary" id="btn-postman" [disabled]="loading" (click)="getPostmanDocs()"
                    data-spinner-color="#333333">
                Get Postman Docs
            </button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-4">
            <label>
                View Swagger Docs for Service:
            </label>
            <ng-select [loading]="loading" [items]="availableRoutes" [multiple]="false" [clearable]="false"
                       [(ngModel)]="selectedRoute" (ngModelChange)="routeSelected()">
            </ng-select>
        </div>
    </div>
</div>
<hr>

<div class="swagger-container"></div>
