// Extensions
import { Observable } from 'rxjs';
import { LaddaButton } from 'ladda';


declare module 'rxjs/internal/Observable' {

    interface Observable<T> {
        laddaSubscribe(
            this: Observable<T>,
            laddaButton: LaddaButton,
            nextFunc: (response: T) => void,
            error: (error: any) => void
        ): void;
    }
}

Observable.prototype.laddaSubscribe =
    function (
        this: Observable<any>,
        laddaButton: LaddaButton,
        nextFunc: (response: any) => void,
        error: (error: any) => void
    ): void {
        laddaButton.start();
        this.subscribe(
            action => {
                laddaButton.stop();
                nextFunc(action);
            },
            (e) => {
                laddaButton.stop();
                error(e);
            });
    };

