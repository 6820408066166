import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class AbstractService {

    protected baseUrl: string;
    protected serviceRouterUrl: string;

    constructor(protected http: HttpClient) {
        this.baseUrl = environment.dashboardUrl;
        this.serviceRouterUrl = environment.serviceRouterUrl;
    }

    protected headers(): HttpHeaders {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Token': this.getAccessToken() || '',
        });
        // console.log('headers: ', headers);
        return headers;
    }

    protected serviceRouterHeaders(service: string): HttpHeaders {
        const token = this.getAccessToken();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Token': token || '',
            'Service': service || '',
            'Version': '1',
            'Platform': 'API_Dashboard'
        });
        // console.log('headers: ', headers);
        return headers;
    }

    protected priceDropHeaders(service: string): HttpHeaders {
        const token = this.getAccessToken();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Token': token || ''
            // 'Service': service || '',
            // 'Version': '1',
            // 'Platform': 'API_Dashboard'
        });
        // console.log('headers: ', headers);
        return headers;
    }

    protected cityHeaders(): HttpHeaders {
        const token = this.getAccessToken();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Token': token || '',
            'Accept': 'application/json',
        });
        return headers;
    }

    protected platformServiceRouterHeaders(platform: string): HttpHeaders {
        const token = this.getAccessToken();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Token': token || '',
            'Service': '',
            'Version': '',
            'Platform': platform || ''
        });
        // console.log('headers: ', headers);
        return headers;
    }
    getAccessToken(): string {
        return localStorage.getItem('access_token');
    }
}
