<button class="btn btn-sm btn-link" (click)="showSummariesModal()">See all Scheduled and Delivered Notifications</button>
<div>
    <h3>Find Users:</h3>
    <button type="button" class="btn btn-info" (click)="switchToQuerySection('location')">
        Near Location
    </button>
    <button type="button" class="btn btn-info" (click)="switchToQuerySection('preference')">
        With Preference
    </button>
    <button type="button" class="btn btn-info" (click)="switchToQuerySection('home-city')">
        With Home City
    </button>
    <button type="button" class="btn btn-info" (click)="switchToQuerySection('last-session')">
        With Last Session Dates
    </button>
</div>
<div class="card" [hidden]="selectedFindUsersSection != 'location'">
    <div class="notification-query-section">
        <div class="form-group row">
            <div class="col-sm-8 mt-2">
                <label for="cityId">Query Users Near City</label>
                <ng-select id="cityId" [virtualScroll]="true" [items]="cities" bindLabel="label"
                           [multiple]="false" [loading]="!cities" [(ngModel)]="queryCity">
                </ng-select>
            </div>
            <div class="col-sm-4 mt-2">
                <label for="description">Query Radius km (250 max)</label>
                <input type="number" class="form-control notification-input" id="description" aria-describedby="description" [(ngModel)]="queryCityRadiusKm">
            </div>
            <div class="col-sm-12 mt-2">
                <label for="preferenceId">Preference (optional)</label>
                <ng-select id="preferenceId" [virtualScroll]="true" [items]="allPreferences" bindLabel="title"
                           [multiple]="false" [loading]="!allPreferences" [(ngModel)]="queryPreferenceObject">
                </ng-select>
            </div>
            <div class="col-sm-4 mt-2">
                <label for="queryDate">Query Data Since: (optional)</label>
                <div class="input-group notification-input">
                    <input id="queryDate" type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                           [bsConfig]="dateConfig" [(ngModel)]="queryCitySinceDate">
                    <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
                </div>
            </div>
            <div class="col-sm-12 mt-2">
                <label for="queryButton"></label>
                <button id="queryButton" type="button" class="btn query-btn btn-done mt-2" (click)="queryUsersForCity()">Query</button>
            </div>
        </div>
    </div>
</div>

<div class="card" [hidden]="selectedFindUsersSection != 'preference'">
    <div class="notification-query-section">
        <div class="form-group row">
            <div class="col-sm-7 mt-2">
                <label for="performerQueryDate">Look Up Performers With Events Added Since Date</label>
                <div class="input-group notification-input">
                    <input id="performerQueryDate" type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                           [bsConfig]="dateConfig" [(ngModel)]="updatedSinceDate">
                    <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
                </div>
            </div>
            <div class="col-sm-4 mt-2">
                <label for="performerQuery"></label>
                <button id="performerQuery" type="button" class="btn query-btn btn-done mt-2" (click)="findPerformersUpdatedSinceDate()">Look Up</button>
            </div>
            <div class="col-sm-1 mt-2">
                <mat-progress-spinner class="col-sm-6" [diameter]="32" color="accent" mode="indeterminate" *ngIf="activePerformerLookupLoading"></mat-progress-spinner>
            </div>
            <div class="col-sm-12 mt-2" [hidden]="!activePerformerLookupResults">
                <mat-paginator id="paginator" [length]="activePerformerLookupDataSource.data.length" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
                <mat-table [dataSource]="activePerformerLookupDataSource" aria-colspan="2" matSort>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                        <mat-cell *matCellDef="let item" class="grey-text" (click)="activePerformerClicked(item)">{{item.name}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="eventCount">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Event Count</mat-header-cell>
                        <mat-cell *matCellDef="let item" class="grey-text">{{item.eventCount}} Events</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Added Date</mat-header-cell>
                        <mat-cell *matCellDef="let item">{{item.lastSignificantAddition * 1000 | date}}</mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="['name', 'eventCount', 'date']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['name', 'eventCount', 'date']"></mat-row>
                </mat-table>
            </div>
            <div class="col-sm-12 mt-2">
                <label for="queryPreferenceId">Preference</label>
                <ng-select id="queryPreferenceId" [virtualScroll]="true" [items]="allPreferences" bindLabel="title"
                           [multiple]="false" [loading]="!allPreferences" [(ngModel)]="queryPreferenceObject">
                </ng-select>
            </div>
            <div class="col-sm-12 mt-2">
                <label for="prefQueryButton"></label>
                <button id="prefQueryButton" type="button" class="btn query-btn btn-done mt-2" (click)="queryUsersWithPreference()">Query</button>
            </div>
        </div>
    </div>
</div>

<div class="card" [hidden]="selectedFindUsersSection != 'home-city'">
    <div class="notification-query-section">
        <div class="form-group row">
            <div class="col-sm-8 mt-2">
                <label for="homecityId">Query With Home City</label>
                <ng-select id="homecityId" [virtualScroll]="true" [items]="cities" bindLabel="label"
                           [multiple]="false" [loading]="!cities" [(ngModel)]="queryCity">
                </ng-select>
            </div>
            <div class="col-sm-12 mt-2">
                <label for="queryHomeCityPreferenceId">Preference (optional)</label>
                <ng-select id="queryHomeCityPreferenceId" [virtualScroll]="true" [items]="allPreferences" bindLabel="title"
                           [multiple]="false" [loading]="!allPreferences" [(ngModel)]="queryPreferenceObject">
                </ng-select>
            </div>
            <div class="col-sm-12 mt-2">
                <label for="homecityQueryButton"></label>
                <button id="homecityQueryButton" type="button" class="btn query-btn btn-done mt-2" (click)="queryUsersWithHomeCity()">Query</button>
            </div>
        </div>
    </div>
</div>

<div class="card" [hidden]="selectedFindUsersSection != 'last-session'">
    <div class="notification-query-section">
        <div class="form-group row">
            <div class="col-sm-4 mt-2">
                <label for="afterQueryDate">After Date:</label>
                <div class="input-group notification-input">
                    <input id="afterQueryDate" type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                           [bsConfig]="dateConfig" [(ngModel)]="queryAfterDate">
                    <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
                </div>
            </div>
            <div class="col-sm-4 mt-2">
                <label for="beforeQueryDate">Before Date:</label>
                <div class="input-group notification-input">
                    <input id="beforeQueryDate" type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                           [bsConfig]="dateConfig" [(ngModel)]="queryBeforeDate">
                    <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
                </div>
            </div>
            <div class="col-sm-6 mt-2">
                <div class="input-group">
                    <input type="checkbox" class="mat-checkbox" id="deviceCheckbox" [(ngModel)]="getDeviceIds">
                    <label class="checkbox-label" for="deviceCheckbox">Get user devices without accounts</label>
                </div>
            </div>
            <div class="col-sm-12 mt-2">
                <label for="sessionQueryButton"></label>
                <button id="sessionQueryButton" type="button" class="btn query-btn btn-done mt-2" (click)="queryUsersWitLastSessionDates()">Query</button>
            </div>
        </div>
    </div>
</div>

<div class="card" [hidden]="!queryResults && !queryLoading">
    <div class="notification-query-section">
        <h4 class="mt-3 ml-3 grey-text">Query Results:</h4>
        <div class="row">
            <mat-progress-spinner class="col-sm-6" [diameter]="32" color="accent" mode="indeterminate" *ngIf="queryLoading"></mat-progress-spinner>
            <p class="col-sm-6" *ngIf="this.queryResults!=null">
                {{this.queryResults.length}} users found.
                <button type="button" class="btn btn-sm btn-primary" (click)="addResultsToDeploymentGroups()" [disabled]="this.queryResults.length === 0">+ Add Deployment Group</button>
            </p>
        </div>
    </div>
</div>

<div [hidden]="deploymentGroups.length == 0">
    <h3>Create Notification:</h3>
</div>

<div class="card" [hidden]="deploymentGroups.length == 0">
    <div class="notification-query-section row">
        <h4 class="mt-3 ml-3 grey-text">Deployment Groups:</h4>
        <mat-table [dataSource]="deploymentGroupsTableDataSource" aria-colspan="2">
            <ng-container matColumnDef="groupDescription">
                <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                <mat-cell *matCellDef="let item" class="grey-text">{{item.groupDescription}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="users">
                <mat-header-cell *matHeaderCellDef>Users</mat-header-cell>
                <mat-cell *matCellDef="let item" class="grey-text">{{item.users.length}} Users</mat-cell>
            </ng-container>
            <ng-container matColumnDef="remove">
                <mat-header-cell *matHeaderCellDef>Remove</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <button type="button" class="btn btn-sm btn-danger" (click)="removeFromDeploymentGroups(item)">
                        Remove
                    </button>
                </mat-cell>
            </ng-container>

            <mat-row *matRowDef="let row; columns: ['groupDescription', 'users', 'remove']"></mat-row>
        </mat-table>
        <p class="mt-3 ml-3">Total unique users: {{getUniqueUsers().length}}</p>
    </div>
    <div class="notification-query-section">
        <h4 class="mt-3 ml-3 grey-text">Notification Content:</h4>
        <div class="form-group row">
            <div class="col-sm-12 mt-2">
                <label for="subjectInput">Notification Subject</label>
                <input type="text" class="form-control notification-input" id="subjectInput" [(ngModel)]="notificationSubject">
            </div>
            <div class="col-sm-12 mt-2">
                <label for="messageInput">Notification Message</label>
                <input type="text" class="form-control notification-input" id="messageInput" [(ngModel)]="notificationMessage">
            </div>
            <div class="col-sm-6 mt-2">
                <label for="deliverAfterDate">Deliver After Date/Time:</label>
                <div class="input-group notification-input">
                    <input class="form-control" id="deliverAfterDate" [(ngModel)]="notificationDeliverAfter" [owlDateTimeTrigger]="deliverAfterDate" [owlDateTime]="deliverAfterDate">
                    <span class="input-group-addon" [owlDateTimeTrigger]="deliverAfterDate"><i class="fa fa-calendar"></i></span>
                    <owl-date-time hour12Timer="true" #deliverAfterDate></owl-date-time>
                </div>
            </div>
            <div class="col-sm-6 mt-2">
                <label for="deliverBeforeDate">Deliver Before Date/Time:</label>
                <div class="input-group notification-input">
                    <input class="form-control" id="deliverBeforeDate" [(ngModel)]="notificationDeliverBefore" [owlDateTimeTrigger]="deliverBeforeDate" [owlDateTime]="deliverBeforeDate">
                    <span class="input-group-addon" [owlDateTimeTrigger]="deliverBeforeDate"><i class="fa fa-calendar"></i></span>
                    <owl-date-time hour12Timer="true" #deliverBeforeDate></owl-date-time>
                </div>
            </div>
        </div>
    </div>
    <div class="notification-query-section">
        <h4 class="mt-3 ml-3 grey-text">Notification Behaviour:</h4>
        <div class="form-group row">
            <div class="col-sm-12 mt-2">
                <div class="input-group">
                    <mat-checkbox [(ngModel)]="saveToNotificationCenter">Save Notification in Notification Center</mat-checkbox>
                </div>
            </div>
            <mat-radio-group aria-label="Notification Action Type" [(ngModel)]="notificationType">
                <mat-radio-button value="default" class="radio-button">Default (no action)</mat-radio-button>
                <mat-radio-button value="suggested-plan" class="radio-button">Suggested Plan</mat-radio-button>
                <mat-radio-button value="event-details" class="radio-button">Event Details </mat-radio-button>
                <mat-radio-button value="planning" class="radio-button">Planning </mat-radio-button>
                <mat-radio-button value="search" class="radio-button">Search</mat-radio-button>
            </mat-radio-group>
            <div class="col-sm-12 mt-2">
                <div class="input-group">
                    <label for="deliveryPriority">Delivery Priority</label>
                    <ng-select id="deliveryPriority"
                               [items]="notificationPriorities"
                               [(ngModel)]="notificationSelectedPriority"
                               [clearable]="false"
                               [required]="true"
                               [closeOnSelect]="true">
                    </ng-select>
                </div>
            </div>
            <div class="col-sm-6 mt-2" [hidden]="notificationType === 'default' || notificationType === 'suggested-plan'">
                <label for="startDate">Search Start Date:</label>
                <div class="input-group notification-input">
                    <input id="startDate" type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                           [bsConfig]="dateConfig" [(ngModel)]="notificationSearchStartDate">
                    <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
                </div>
            </div>
            <div class="col-sm-6 mt-2" [hidden]="notificationType === 'default' || notificationType === 'suggested-plan'">
                <label for="endDate">Search End Date</label>
                <div class="input-group notification-input">
                    <input id="endDate" type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                           [bsConfig]="dateConfig" [(ngModel)]="notificationSearchEndDate">
                    <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
                </div>
            </div>
            <div class="col-sm-6 mt-2" [hidden]="notificationType === 'default' || notificationType === 'suggested-plan'">
                <label for="searchCity">City</label>
                <ng-select id="searchCity"
                           [virtualScroll]="true"
                           [items]="cities"
                           bindLabel="label"
                           [loading]="!cities"
                           [multiple]="notificationType === 'search'"
                           [(ngModel)]="notificationSearchCities">
                </ng-select>
                <button class="btn query-btn btn-done mt-2" (click)="findEventsForNotification()" *ngIf="notificationType !== 'search'">Find Events</button>
            </div>
            <div class="col-sm-6 mt-2" [hidden]="notificationType !== 'search'">
                <label for="searchEvents">Performer</label>
                <ng-select id="searchPerformers"
                           bindLabel="name"
                           [virtualScroll]="true"
                           [items]="notificationSearchPerformers"
                           [(ngModel)]="notificationSearchSelectedPerformers"
                           [closeOnSelect]="false"
                           [multiple]="notificationType !== 'event-details'">
                    <ng-template ng-option-tmp let-item="item">
                        {{item.name}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-sm-6 mt-2" [hidden]="notificationType === 'default' || notificationType === 'search' || notificationType === 'suggested-plan'">
                <label for="searchEvents">Events</label>
                <ng-select id="searchEvents"
                           bindLabel="eventName"
                           [virtualScroll]="true"
                           [items]="notificationSearchEventsResults || []"
                           [loading]="notificationSearchEventsLoading"
                           [(ngModel)]="notificationSearchSelectedEvents"
                           [closeOnSelect]="false"
                           [multiple]="notificationType !== 'event-details'">
                    <ng-template ng-option-tmp let-item="item">
                        {{item.eventName}}<br/>
                        <span class="text-muted">&nbsp;{{item.eventDateString | date:'MMM d, y, h:mm a'}}</span>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-sm-12 mt-2">
                <div class="input-group">
                    <button class="btn btn-primary btn-lg" *ngIf="!notificationScheduling" (click)="sendNotifications()">Send Notifications</button>
                </div>
            </div>
        </div>
    </div>
</div>
