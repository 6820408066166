import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ExplorePlansService } from 'services/ExplorePlans.service';
import { Category } from 'models/category';
import { CreateExplorePlan } from 'models/createExplorePlan';
import { v4 as uuid } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-categories-modal',
  templateUrl: './add-categories-modal.component.html',
  styleUrls: ['./add-categories-modal.component.scss']
})
export class AddCategoriesModalComponent implements OnInit {
  public onPlanCreated: Subject<boolean> = new Subject<boolean>();
  addCategoriesForm: FormGroup
  searching: boolean;
  submitted: boolean;
  submitting: boolean;
  categories: Category[];
  error: string;

  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private explorePlansService: ExplorePlansService, public toastr: ToastrService) { }

  ngOnInit() {
    this.explorePlansService.getCategories().subscribe(categories => {
      this.categories = categories
    })

    this.addCategoriesForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(4)]],
      expiryDate: [new Date(), Validators.required],
      categoryId: [undefined, [Validators.required, Validators.minLength(1)]],
      image: [undefined, Validators.required]
    });
  }

  imageEvent(image) {
    this.addCategoriesForm.patchValue({image})
  }

  get formValue() {
    return this.addCategoriesForm.value as CreateExplorePlan;
  }

  get f() {
    return this.addCategoriesForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.addCategoriesForm.invalid) {
      return
    }

    this.submitting = true

    const image = (this.formValue as any).image
    this.explorePlansService.getImageUploadUrl(image.fileName, image.uuid).subscribe(signedUrl => {
      this.explorePlansService.putImageUploadUrl(signedUrl.url, image.url, image.fileName).subscribe(imageRes => {
        const dto = Object.assign({}, this.formValue)
        dto.id = uuid()
        dto.imageId = image.uuid
        dto.expiryDate = Math.floor(new Date(this.formValue.expiryDate).setHours(23, 59, 59, 999).valueOf() / 1000)
        dto.action = "ExplorePlanActionCategoryPerformers"
    
        this.explorePlansService.createExplorePlan(dto).subscribe(res => {
          this.bsModalRef.hide()
          this.toastr.success('Category Item Saved', 'Success!')
          this.onPlanCreated.next(true);
        }, err => {
          this.submitting = false
          this.error = err.error ? err.error.message : err.message
        });
      })
    })
  }

}
