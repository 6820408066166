<div class="row">
    <div class="col-sm-12 mt-2">
        <label for="searchCity">Select Cities</label>
        <ng-select id="searchCity"
                   [virtualScroll]="true"
                   [items]="cities"
                   bindLabel="label"
                   [loading]="!cities"
                   [multiple]="true"
                   [(ngModel)]="selectedCities">
        </ng-select>
    </div>
    <div class="col-sm-6 mt-2">
        <label for="compValue">Comparison Value</label>
        <input type="number" class="form-control notification-input" id="compValue" [step]="0.1" [(ngModel)]="comparisonValue">
    </div>
    <div class="col-sm-6 mt-2">
        <label for="queryDate">Data Since: (optional)</label>
        <div class="input-group notification-input">
            <input id="queryDate" type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                   [bsConfig]="dateConfig" [(ngModel)]="querySinceDate">
            <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
        </div>
    </div>
    <div class="col-sm-12 mt-2">
        <mat-radio-group aria-label="Search Type" [(ngModel)]="searchType">
            <mat-radio-button value="TicketPriceType_MinPriceRelativeTo7DayAvg" class="radio-button">
                Min Price Relative To 7 Day Avg
            </mat-radio-button>
            <br>
            <mat-radio-button value="TicketPriceType_MinPriceRelativeTo30DayAvg" class="radio-button">
                Min Price Relative To 30 Day Avg
            </mat-radio-button>
            <br>
            <mat-radio-button value="TicketPriceType_MinPriceRelativeToPerformerEvents" class="radio-button">
                Min Price Relative to Performer Events
            </mat-radio-button>
            <br>
            <mat-radio-button value="TicketPriceType_TicketCountRelativeTo5DayAvg" class="radio-button">
                Ticket Count Relative to 5 Day Avg
            </mat-radio-button>
            <br>
        </mat-radio-group>
    </div>
    <div class="col-sm-12 mt-2">
        <button class="btn query-btn btn-done mt-12" (click)="query()">Query</button>
    </div>
</div>

<div class="row mt-2">
    <div class="col-sm-4">
        <alert type="danger" class="text-center" [hidden]="!noResults || loading">
            <strong>No Results</strong>
        </alert>
    </div>
</div>

<div class="row mt-2">
    <div class="col-sm-4">
        <mat-progress-spinner color="accent" mode="indeterminate" *ngIf="loading"></mat-progress-spinner>
    </div>
</div>

<!--query results-->
<div class="row" [hidden]="resultsDataSource.data == null || resultsDataSource.data.length === 0 || loading">
    <mat-table class="col-sm-12" [dataSource]="resultsDataSource" matSort>
        <ng-container matColumnDef="eventName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Event</mat-header-cell>
            <mat-cell *matCellDef="let item" class="grey-text">{{item.event.eventName}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
            <mat-cell *matCellDef="let item" class="grey-text">{{item.event.eventDateString | date}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="city">
            <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
            <mat-cell *matCellDef="let item" class="grey-text">{{cityNameForId(item.event.cityId)}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="ticketCount">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Ticket Count</mat-header-cell>
            <mat-cell *matCellDef="let item" class="grey-text">{{item.event.ticketCount}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="minPrice">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Min Price</mat-header-cell>
            <mat-cell *matCellDef="let item" class="grey-text">{{item.event.minPrice | currency}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="priceDrop">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Price Drop</mat-header-cell>
            <mat-cell *matCellDef="let item" class="grey-text">{{getPriceDropForEvent(item) | currency}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="priceDropPercent">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Price Drop %</mat-header-cell>
            <mat-cell *matCellDef="let item"
                      class="grey-text">{{getPriceDropPercentForEvent(item) | percent}}</mat-cell>
        </ng-container>
        <mat-header-row
                *matHeaderRowDef="['eventName', 'date', 'city', 'ticketCount', 'minPrice', 'priceDrop', 'priceDropPercent']"></mat-header-row>
        <mat-row
                *matRowDef="let row; columns: ['eventName', 'date', 'city', 'ticketCount', 'minPrice', 'priceDrop', 'priceDropPercent']"
                (click)="showEventModal(row)"></mat-row>
    </mat-table>
</div>
