import { Component, OnInit } from '@angular/core';
import { Utils } from '../shared/utils';
import { City } from '../../models/city';
import { BsModalService } from 'ngx-bootstrap';
import { NotificationService } from '../../services/NotificationService.service';
import { DashboardService } from '../../services/Dashboard.service';
import { ExplorePlansService } from '../../services/ExplorePlans.service';
import { ToastrService } from 'ngx-toastr';
import { Performer } from '../../models/performer';
import { PreferenceObject } from '../../models/preferenceObject';
import { GetEventsForPlanDto } from '../../models/getEventsForPlanDto';
import { DynamicLink } from '../../models/DynamicLink';

@Component({
    selector: 'app-link-generator',
    templateUrl: './link-generator.component.html',
    styleUrls: ['./link-generator.component.scss']
})
export class LinkGeneratorComponent implements OnInit {

    linkTypes = [
        {'name': 'Event Details', 'linkType': 'DynamicLink_EventDetails'},
        {'name': 'New Plan', 'linkType': 'DynamicLink_NewPlan'},
        {'name': 'Perform Search', 'linkType': 'DynamicLink_PerformSearch'},
        {'name': 'Explore', 'linkType': 'DynamicLink_Explore'},
        {'name': 'User Preferences', 'linkType': 'DynamicLink_UserPreferences'},
        {'name': 'New Empty Plan', 'linkType': 'DynamicLink_NewEmptyPlan'},
        {'name': 'New Crew', 'linkType': 'DynamicLink_NewCrew'},
    ];

    performers: Performer[] = null;
    selectedPerformers: Performer[] = [];
    cities: City[];
    selectedCities: City[];
    selectedLinkType = '';
    startDate: Date;
    endDate: Date;
    eventResults: any[] = [];
    eventResultsLoading = false;
    selectedEvents: any[] = [];
    dateConfig = Utils.getBSDateConfig();
    generatedLink: DynamicLink = null;
    generatingLink = false;

    constructor(private modalService: BsModalService,
                private notificationService: NotificationService,
                private dashboardService: DashboardService,
                private explorePlansService: ExplorePlansService,
                public toastr: ToastrService) {
    }

    linkTypeChanged() {
        this.selectedCities = null;
        this.selectedEvents = null;
        this.selectedPerformers = null;
    }

    datesHidden(): boolean {
        return this.selectedLinkType !== 'DynamicLink_EventDetails' &&
            this.selectedLinkType !== 'DynamicLink_NewPlan' &&
            this.selectedLinkType !== 'DynamicLink_PerformSearch';
    }

    cityHidden(): boolean {
        return this.selectedLinkType !== 'DynamicLink_EventDetails' &&
            this.selectedLinkType !== 'DynamicLink_NewPlan' &&
            this.selectedLinkType !== 'DynamicLink_PerformSearch';
    }

    performersHidden(): boolean {
        return this.selectedLinkType !== 'DynamicLink_PerformSearch';
    }

    findEventsHidden(): boolean {
        return this.selectedLinkType !== 'DynamicLink_EventDetails' && this.selectedLinkType !== 'DynamicLink_NewPlan';
    }

    ngOnInit() {
        this.initializeAppData();
    }

    initializeAppData() {
        this.explorePlansService.getCities().subscribe(cities => {
            this.cities = cities.map(c => {
                (c as any).label = c.city + ', ' + c.state;
                return c;
            });
        });

        this.explorePlansService.getActivePerformers().subscribe(performers => {
            this.performers = performers;
        }, e => {
            this.toastr.error(e.message);
        });
    }

    generateLink() {
        this.generatedLink = null;
        let metadata: object = null;
        switch (this.selectedLinkType) {
            case 'DynamicLink_EventDetails':
                metadata = this.generateEventDetailsMetaData();
                break;
            case 'DynamicLink_NewPlan':
                metadata = this.generateNewPlanMetaData();
                break;
            case 'DynamicLink_PerformSearch':
                metadata = this.generatePerformSearchMetaData();
                break;
        }
        const requestBody = {linkType: this.selectedLinkType};
        if (metadata != null) {
            requestBody['metadata'] = metadata;
        }

        this.generatingLink = true;
        this.notificationService.generateDynamicLink(requestBody).subscribe(result => {
                console.log(result);
                this.generatedLink = result;
                this.generatingLink = false;
            }, error => {
                console.log(error);
                this.generatingLink = false;
                this.toastr.error(error.message);
            }
        );
    }

    findEvents() {
        const selectedCities = this.selectedCities;
        if (!this.startDate || !this.endDate || selectedCities.length !== 1) {
            this.toastr.info('Select dates and city.');
            return;
        }

        const dto: GetEventsForPlanDto = {
            startDate: Utils.formatDate(new Date(this.startDate)),
            endDate: Utils.formatDate(new Date(this.endDate)),
            cityId: selectedCities[0].id,
        };

        this.eventResultsLoading = true;
        this.explorePlansService.getEventsForPlan(dto).subscribe(events => {
            this.eventResults = events;
            this.eventResultsLoading = false;
        }, () => {
            this.eventResults = [];
            this.eventResultsLoading = false;
        });
    }

    generateEventDetailsMetaData(): object {
        const metadata = {};
        metadata['EventId'] = this.selectedEvents[0].id;
        return metadata;
    }

    generatePerformSearchMetaData(): object {
        const metadata = {};
        const startDateString = this.startDate ? Utils.formatDate(this.startDate) : null;
        const endDateString = this.endDate ? Utils.formatDate(this.endDate) : null;
        metadata['StartDate'] = startDateString;
        metadata['EndDate'] = endDateString;
        if (this.selectedPerformers && this.selectedPerformers.length > 0) {
            metadata['PerformerIds'] = this.selectedPerformers.map(p => p.id).join(',');
        }
        if (this.selectedCities && this.selectedCities.length > 0) {
            metadata['CityIds'] = this.selectedCities.map(c => c.id).join(',');
        }
        return metadata;
    }

    generateNewPlanMetaData(): object {
        const metadata = {};
        const startDateString = this.startDate ? Utils.formatDate(this.startDate) : null;
        const endDateString = this.endDate ? Utils.formatDate(this.endDate) : null;
        metadata['StartDate'] = startDateString;
        metadata['EndDate'] = endDateString;
        if (this.selectedEvents && this.selectedEvents.length > 0) {
            metadata['EventIds'] = this.selectedEvents.map(e => e.id).join(',');
        }
        metadata['CityId'] = this.selectedCities[0].id;
        return metadata;
    }


    copyToClipboard(value: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.toastr.success('Link Copied to Clipboard');
    }
}
