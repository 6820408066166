import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/AuthService.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted: boolean;
  loading: boolean;
  loginForm: FormGroup;
  error: string;

  constructor(private auth: AuthService, private formBuilder: FormBuilder, private router: Router) {
    this.auth.logout();
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.auth.authenticateUser(this.f.username.value, this.f.password.value)
      .subscribe(
        data => {
          console.log(data)
          if (data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
            return this.router.navigate(['new-password'], { 
              state: { session: data.Session, 
                srpa: data.ChallengeParameters['USER_ID_FOR_SRP'],
                username: this.f.username.value } 
            })
          }

          this.auth.setSession(data.AuthenticationResult)
          this.router.navigate(['dashboard']);
        },
        error => {
          console.log(error)
          this.error = error.error ? error.error.message : error.message
          this.loading = false;
        });
  }

}
