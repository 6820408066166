<div class="modal-header">
  <h4 class="modal-title pull-left">{{eventTicketPrices.event.eventName}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">Close</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm-6">
      City: {{cityName}}
    </div>
    <div class="col-sm-6">
      Date/Time: {{eventTicketPrices.event.eventDateString | date:'MMM d, y, h:mm a'}}
    </div>
    <div class="col-sm-6">
      Current Min Price: {{eventTicketPrices.event.minPrice | currency}}
    </div>
    <div class="col-sm-6">
      Current Max Price: {{eventTicketPrices.event.maxPrice | currency}}
    </div>
    <div class="col-sm-6">
      Current Ticket Count: {{eventTicketPrices.event.ticketCount}}
    </div>
    <div class="col-sm-6">
      <a href="{{eventTicketPrices.event.url}}" target="_blank">View on Vivid</a>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <canvas #priceChart></canvas>
    </div>
  </div>
</div>
