<div class="row" *ngIf="loadingTimeSeriesData">
    <div class="col-sm-12">
        <i class="loading-indicator fa fa-circle-o-notch fa-spin fa-4x"></i>
    </div>
</div>
<div class="row negative-margin" [hidden]="loadingTimeSeriesData">
    <div class="col-sm-4">
        <h3 class="health-text">Total Invocations: {{totalInvokesString}}</h3>
    </div>
    <div class="col-sm-4">
        <h3 class="health-text" [style.color]="healthPercentageString === '100%' ? '#63D56F' : '#FA4545'">
            Health: {{healthPercentageString}}</h3>
    </div>
    <div class="col-sm-4">
        <h3 class="health-text">Last 24 Hours</h3>
    </div>
</div>
<div class="row mt-0" [hidden]="loadingTimeSeriesData">
    <div class="col-sm-7">
        <h3>Currently Viewing: {{currentService}}
            <button class=" btn-sm btn-link" (click)="toggleServiceSelection()">
                Select Service
            </button>
        </h3>
        <div [hidden]="!showServiceSelection">
            <ng-select id="selectService"
                       [virtualScroll]="true"
                       [items]="directory"
                       [multiple]="false"
                       [clearable]="false"
                       [(ngModel)]="currentService"
                       (ngModelChange)="serviceSelected()">
            </ng-select>
        </div>
    </div>
    <div class="col-md-6">
        <div class="serviceInfo">
            <canvas #invokeChart></canvas>
        </div>
    </div>
    <div class="col-md-6">
        <div class="serviceInfo">
            <canvas #responseTimeChart></canvas>
        </div>
    </div>
    <div class="col-md-6">
        <div class="serviceInfo">
            <table class="endpoint-table" [hidden]="currentServiceTotalInvocations == 0">
                <tr>
                    <td>Invocations:</td>
                    <td class="text-right">{{currentServiceTotalInvocations}}</td>
                </tr>
                <tr>
                    <td>OK Count:</td>
                    <td class="text-right">{{currentServiceTotalOK}}</td>
                </tr>
                <tr>
                    <td>Error Count:</td>
                    <td class="text-right">{{currentServiceTotalErrors}}</td>
                </tr>
                <tr>
                    <td>Fault Count:</td>
                    <td class="text-right">{{currentServiceTotalFaults}}</td>
                </tr>
                <tr>
                    <td>Avg Response Time:</td>
                    <td class="text-right">{{currentServiceAvgResponseTime}}s</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="col-md-6">
        <div class="serviceInfo">
            <div class="col-sm-12 mt-2" *ngIf="loadingTraceData">
                <i class="loading-indicator fa fa-circle-o-notch fa-spin fa-4x"></i>
            </div>
            <div *ngIf="currentService.length > 0 && loadingTraceData == false">
                <table class="endpoint-table">
                    <tr>
                        <th colspan="2">Endpoint</th>
                        <th class="text-right">2XX</th>
                        <th class="text-right">4XX</th>
                        <th class="text-right">5XX</th>
                    </tr>
                    <tr *ngFor="let endpoint of currentServiceEndpoints"
                        [style.color]="getErrorStatusCountForEndpoint(endpoint) > 0 ? '#FA4545' : '#888888'">
                        <td colspan="2">{{getShortenedEndpointName(endpoint)}}</td>
                        <td class="text-right">{{getHttpStatusCountForEndpoint(endpoint, 200)}}</td>
                        <td class="text-right">{{getHttpStatusCountForEndpoint(endpoint, 400)}}</td>
                        <td class="text-right">{{getHttpStatusCountForEndpoint(endpoint, 500)}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

