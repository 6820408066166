export class TraceSummaryRequest {
    serviceName: string;
    endTime: number;
    startTime: number;
    nextKey: string;
}

export interface TraceSummary {
    apiId: string;
    id: string;
    stage: string;
    duration: number;
    httpMethod: string;
    httpStatus: number;
    httpUrl: string;
    responseTime: number;
    timestamp: Date;
}

export interface TraceSummaryResponse {
    traceSummaryMap: Map<string, TraceSummary[]>;
    nextKey: string;
}
