import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { v4 as uuid } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subject} from 'rxjs';
import { PromotionService } from '../../../services/Promotion.service';
import { Promotion } from '../../../models/promotion';

@Component({
    selector: 'app-add-promotion-modal',
    templateUrl: './add-promotion-modal.component.html',
    styleUrls: ['./add-promotion-modal.component.scss']
})
export class AddPromotionModalComponent implements OnInit {
    public onPromotionCreated: Subject<boolean> = new Subject<boolean>();
    addPromotionForm: FormGroup;
    submitted: boolean;
    submitting: boolean;
    actionTypes: string[] = [
        'PromotionAction_CreateAccount',
        'PromotionAction_CreateCrew',
        'PromotionAction_SendCrewInvites',
        'PromotionAction_CreatePlan',
        'PromotionAction_AddEventsToPlan',
        'PromotionAction_PurchaseTickets',
        'PromotionAction_PurchaseAccommodations',
        'PromotionAction_ShareToInstagram',
        'PromotionAction_ShareToFacebook',
        'PromotionAction_CreateCustomEvent',
        'PromotionAction_AddExpensesToPlan',
        'PromotionAction_ImportMusic'];
    metadataOperators = [
        'MetadataCompletionOperator_StringEquals',
        'MetadataCompletionOperator_IntegerGreaterThan',
        'MetadataCompletionOperator_IntegerLessThan',
        'MetadataCompletionOperator_IntegerEqual'];

    error: string;

    constructor(public bsModalRef: BsModalRef,
                private formBuilder: FormBuilder,
                private promotionService: PromotionService,
                public toastr: ToastrService) {
    }

    ngOnInit() {
        this.addPromotionForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(1)]],
            details: ['', [Validators.required, Validators.minLength(1)]],
            successMessage: ['', [Validators.required, Validators.minLength(1)]],
            endDate: [new Date(), Validators.required],
            image: [undefined, Validators.required],
            steps: this.formBuilder.array([this.createStep()])
        });
    }

    createStep(): any {
        return this.formBuilder.group({
            successMessage: '',
            promptMessage: '',
            detailedPromptMessage: '',
            action: [null, Validators.required],
            metadataCompletionOperator: null,
            metadataCompletionValue: null,
            image: [undefined, Validators.required],
        });
    }

    removeStep(stepIndex: number): any {
        const steps = this.addPromotionForm.controls.steps as FormArray;
        steps.removeAt(stepIndex);
    }

    imageEvent(image, step: number) {
        if (step != null) {
            const stepControl = (this.addPromotionForm.controls.steps as FormArray).controls[step];
            stepControl.patchValue({image});
        } else {
            this.addPromotionForm.patchValue({image});
            console.log(this.addPromotionForm.controls.steps[step]);
        }
    }

    get stepControls() {
        return (this.addPromotionForm.controls.steps as FormArray).controls;
    }

    get formValue() {
        return this.addPromotionForm.value as Promotion;
    }

    get f() {
        return this.addPromotionForm.controls;
    }

    addStepToPromo() {
        const steps = this.addPromotionForm.controls.steps as FormArray;
        steps.push(this.createStep());
    }

    onSubmit() {
        if (this.addPromotionForm.invalid) {
            console.log(this.addPromotionForm);
            return;
        }

        // this.submitting = true;
        const image = (this.formValue as any).image;
        const stepImages = (this.formValue as any).steps.map(s => s.image);
        const allImages = [image, ...stepImages];
        const imageOperations: Observable<any>[] = [];
        this.submitting = true;
        allImages.forEach(i => {
            const obs = new Observable<any>(o => {
                this.promotionService.getImageUploadUrl(i.fileName, i.uuid).subscribe(signedUrl => {
                    this.promotionService.putImageUploadUrl(signedUrl.url, i.url, i.fileName).subscribe(imageRes => {
                        o.next(imageRes);
                        o.complete();
                    }, error => {
                        console.log(error);
                    });
                }, error => {
                    console.log(error);
                });
            });
            imageOperations.push(obs);
        });

        forkJoin(imageOperations).subscribe(r => {
            console.log(r);
            const dto = Object.assign({}, this.formValue);
            dto.id = uuid();
            dto.imageId = image.uuid;
            (dto as any).image = null;
            dto.endDate = Math.floor(new Date(this.formValue.endDate).setHours(23, 59, 59, 999).valueOf() / 1000);
            dto.steps.forEach((s, i) => {
                s.imageId = (s as any).image.uuid;
                (s as any).image = null;
                s.priority = i + 1;
            });

            this.promotionService.createPromotion(dto).subscribe(res => {
                this.bsModalRef.hide();
                this.toastr.success('New Promotion Saved', 'Success!');
                this.onPromotionCreated.next(true);
            }, err => {
                this.submitting = false;
                this.error = err.error ? err.error.message : err.message;
            });
        }, error => {
            console.log(error);
            this.toastr.error('Error Uploading Images');
        });
    }

}
