export interface EventTicketPrices {
    event:  Event;
    prices: Price[];
}

export interface Event {
    performerId:           string;
    id:                    string;
    ageRestriction:        number;
    chartUrl:              string;
    cityId:                string;
    eventDateString:       string;
    eventDay:              Date;
    eventName:             string;
    eventTypeId:           string;
    masterPerformer:       boolean;
    secondaryPerformerIds: any[];
    searchCount:           number;
    venueId:               string;
    totalPerformerRank:    number;
    timezone:              string;
    listingCount:          number;
    maxPrice:              number;
    minPrice:              number;
    ticketCount:           number;
    url:                   string;
    vividId:               number;
    eventDate:             number;
    lastUpdated:           number;
    lastSavedTicketPrice:  number;
    isCancelled:           boolean;
}

export class Price {
    eventId:                           string;
    timestamp:                         number;
    masterPerformerId:                 string;
    totalPerformerRank:                number;
    cityId:                            string;
    listingCount:                      number;
    ticketCount:                       number;
    maxPrice:                          number;
    minPrice:                          number;
    minPriceRelativeTo30DayAvg:        number;
    minPriceRelativeTo7DayAvg:         number;
    minPriceRelativeToPerformerEvents: number;
    ticketCountRelativeTo5DayAvg:      number;
}
