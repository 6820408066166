import { Injectable } from '@angular/core';
import { CreateExplorePlan } from 'models/createExplorePlan';
import { AbstractService } from './AbstractService.service';
import { Observable } from 'rxjs';
import { Performer } from 'models/performer';
import { LocalStorageService } from './LocalStorage.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { City } from 'models/city';
import { GetEventsForPlanDto } from 'models/getEventsForPlanDto';
import { Category } from 'models/category';
import { Buffer } from 'buffer';
import { PerformerPage } from '../models/performerPage';
import 'rxjs/add/operator/map';
import { ExplorePlan } from '../models/explorePlan';
import { ApiRouteService } from './ApiRouteService';

@Injectable()
export class ExplorePlansService extends AbstractService {

    constructor(protected http: HttpClient, private localStorageService: LocalStorageService, private apiRouteService: ApiRouteService) {
        super(http);
    }

    public createExplorePlan(createExplorePlan: CreateExplorePlan): Observable<any> {
        const url = this.apiRouteService.getUrl('dashboard-explore', 'create-explore-plan');
        return this.http.post<any>(url, createExplorePlan, {headers: this.headers()});
    }

    public getImageUploadUrl(fileName: string, imageId: string): Observable<any> {
        console.log(fileName);
        console.log(imageId);
        const url = this.apiRouteService.getUrl('dashboard-explore', 'get-explore-plan-upload-url');
        console.log('fileName: ', fileName);
        console.log('imageId: ', imageId);
        return this.http.get<any>(url, {
            headers: this.headers(),
            params: {FileName: fileName, ImageId: imageId}
        });
    }

    public putImageUploadUrl(url: string, file: string, fileName: string) {
        let type = '';
        switch (fileName.split('.').pop()) {
            case 'jpg':
            case 'jpeg':
                type = 'image/jpeg';
                break;
            case 'png':
                type = 'image/png';
                break;
        }

        const buff = new Buffer(file.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        const headers = new HttpHeaders({
            'Content-Type': type,
            'Content-Encoding': 'base64'
        });

        const blob = new Blob([new Uint8Array(buff)]);
        return this.http.put<any>(url, blob, {headers});
    }

    private getActivePerformersPage(lastEvaluatedId: string): Observable<PerformerPage> {
        const url = this.apiRouteService.getUrl('dashboard', 'get-performers');
        const params = {lastEvaluatedId: lastEvaluatedId, ReturnInactive: 'false'};
        return this.http.get<PerformerPage>(url, {headers: this.headers(), params: params});
    }

    public getActivePerformersFromApi(lastEvaluatedId: string): Observable<PerformerPage> {
        return this.getActivePerformersPage(lastEvaluatedId).concatMap((page) => {
            if (page.lastEvaluatedId === '') {
                return new Observable<PerformerPage>(o => o.next(page));
            } else {
                return this.getActivePerformersFromApi(page.lastEvaluatedId).startWith(page);
            }
        });
    }

    public getActivePerformers(): Observable<Performer[]> {
        return new Observable<Performer[]>(observer => {
            const activePerformers: Performer[] = [];
            this.getActivePerformersFromApi('').subscribe(page => {
                activePerformers.push(...page.objects);
                if (!page.lastEvaluatedId) {
                    observer.next(activePerformers);
                }
            });

        });
    }

    public getCities(): Observable<City[]> {
        const url = this.apiRouteService.getUrl('dashboard', 'get-cities');
        return this.localStorageService.observableHttpRequest<City[]>(
            'cities', url, this.headers());
    }

    public getEventsForPlan(getEventsForPlanDto: GetEventsForPlanDto): Observable<Event[]> {
        const params = new HttpParams()
            .set('StartDate', getEventsForPlanDto.startDate)
            .set('EndDate', getEventsForPlanDto.endDate)
            .set('CityId', getEventsForPlanDto.cityId);
        const url = this.apiRouteService.getUrl('dashboard-explore', 'get-events-for-plan');
        return this.http.get<Event[]>(url, {headers: this.headers(), params});
    }

    public getCurrentExplorePlans(includeExpired: boolean): Observable<ExplorePlan[]> {
        const params = new HttpParams().set('IncludeExpired', includeExpired.toString());
        const url = this.apiRouteService.getUrl('dashboard-explore', 'get-explore-plans');
        return this.http.get<ExplorePlan[]>(url, {headers: this.headers(), params});
    }

    public getCategories(): Observable<Category[]> {
        const url = this.apiRouteService.getUrl('dashboard', 'get-categories');
        return this.localStorageService.observableHttpRequest<Category[]>('categories', url, this.headers());
    }

    public deleteExplorePlan(id: string, expiryDate: number): Observable<any> {
        const url = this.apiRouteService.getUrl('dashboard-explore', 'delete-explore-plan');
        return this.http.delete(url,
            {
                headers: this.headers(),
                params: {ExpiryDate: expiryDate.toString(), Id: id},
                responseType: 'text'
            });
    }

    public getAccessToken(): string {
        return localStorage.getItem('access_token');
    }
}
