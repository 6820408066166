import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { City } from 'models/city';
import { ExplorePlansService } from 'services/ExplorePlans.service';
import { CreateExplorePlan } from 'models/createExplorePlan';
import { v4 as uuid } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-city-modal',
  templateUrl: './add-city-modal.component.html',
  styleUrls: ['./add-city-modal.component.scss']
})
export class AddCityModalComponent implements OnInit {
  public onPlanCreated: Subject<boolean> = new Subject<boolean>();
  addCityForm: FormGroup;
  submitting: boolean;
  submitted: boolean;
  cities: City[];
  imgURL: any = 'assets/img/notfound.png';
  error: string;

  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private explorePlansService: ExplorePlansService, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.explorePlansService.getCities().subscribe(cities => {
      this.cities = cities.map(c => {
        (c as any).label = c.city + ', ' + c.state
        return c
      })
    })

    this.addCityForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(4)]],
      expiryDate: [new Date(), Validators.required],
      cityIds: [[], [Validators.required, Validators.minLength(1)]],
      image: ['', Validators.required]
    });
  }

  imageEvent(image) {
    
    this.addCityForm.patchValue({image})
  }

  get formValue() {
    return this.addCityForm.value as CreateExplorePlan;
  }

  get f() {
    return this.addCityForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.addCityForm.invalid) {
      return
    }

    this.submitting = true
    const image = (this.formValue as any).image
    this.explorePlansService.getImageUploadUrl(image.fileName, image.uuid).subscribe(signedUrl => {
      this.explorePlansService.putImageUploadUrl(signedUrl.url, image.url, image.fileName).subscribe(imageRes => {

        const dto = Object.assign({}, this.formValue) // clone form as to not modify the original
        dto.id = uuid()
        // convert expiry to end of day unix timestamp
        dto.expiryDate = Math.floor(new Date(this.formValue.expiryDate).setHours(23, 59, 59, 999).valueOf() / 1000)
        dto.imageId = image.uuid
        dto.action = "ExplorePlanActionCitySearch"
        this.explorePlansService.createExplorePlan(dto).subscribe(res => {
          this.bsModalRef.hide()
          this.toastr.success('City Item Saved', 'Success!')
          this.onPlanCreated.next(true);
        }, err => {
          this.submitting = false
          this.error = err.error ? err.error.message : err.message
        });
      })
    })
  }
}
