<div class="container-fluid">
    <div class="row">

        <div class="col-lg-4 col-sm-6">
            <div class="card">
                <div class="content">
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="icon-big icon-info text-center">
                                <i class="ti-user"></i>
                            </div>
                        </div>
                        <div class="col-xs-9">
                            <div class="numbers">
                                <p>Total Users: {{ allStats?.userStats.totalUserCount }}</p>
                                <p>Daily Active Users: {{ allStats?.userStats.activeUsersCount }}</p>
                                <p>New Users (past 24hrs): {{ allStats?.userStats.newUsersCount }}</p>
                                <p>Total Devices: {{ allStats?.userStats.totalDeviceCount }}</p>
                                <p>Daily Active Devices: {{ allStats?.userStats.activeDevicesCount }}</p>
                                <p>Temp Users: {{ allStats?.userStats.totalTempUserCount }}</p>
                                <p>New Temp Users (past 24hrs): {{ allStats?.userStats.newTempUsersCount }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6">
            <div class="card">
                <div class="content">
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="icon-big icon-info text-center">
                                <i class="ti-announcement"></i>
                            </div>
                        </div>
                        <div class="col-xs-9">
                            <div class="numbers">
                                <p>Total Crews: {{ allStats?.crewStats.totalCrewCount }}</p>
                                <p>Active in 24hrs: {{ allStats?.crewStats.activeCrewCount }}</p>
                                <p>Chats Active in 24hrs: {{ allStats?.crewStats.activeChatCount }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6">
            <div class="card">
                <div class="content">
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="icon-big icon-info text-center">
                                <i class="ti-flag"></i>
                            </div>
                        </div>
                        <div class="col-xs-9">
                            <div class="numbers">
                                <p>Total Plans: {{ allStats?.planStats.totalPlanCount }}</p>
                                <p>Active in 24hrs: {{ allStats?.planStats.activePlanCount }}</p>
                                <p>Total Suggested Plans: {{ allStats?.planStats.totalSuggestedPlanCount }}</p>
                                <p>New Suggested in 24hrs: {{ allStats?.planStats.newSuggestedPlanCount }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6">
            <div class="card">
                <div class="content">
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="icon-big icon-info text-center">
                                <i class="ti-search"></i>
                            </div>
                        </div>
                        <div class="col-xs-9">
                            <div class="numbers">
                                <p>Total Searches: {{ allStats?.searchStats.totalSearchCount }}</p>
                                <p>Last 24hrs: {{ allStats?.searchStats.searchCount }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6">
            <div class="card">
                <div class="content">
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="icon-big icon-info text-center">
                                <i class="ti-microphone"></i>
                            </div>
                        </div>
                        <div class="col-xs-9">
                            <div class="numbers">
                                <span [hidden]="!performers">
                                    <p>Top Searched Performers: </p>
                                    <p [style.font-size]="'12px'" *ngFor="let performerId of allStats?.searchStats.topSearchedPerformerIds">
                                       {{getPerformerFromId(performerId)}}
                                    </p>
                                </span>
                                <span [hidden]="performers">
                                    <p>Loading Top Performers... </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6">
            <div class="card">
                <div class="content">
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="icon-big icon-info text-center">
                                <i class="ti-map"></i>
                            </div>
                        </div>
                        <div class="col-xs-9">
                            <div class="numbers">
                                <span [hidden]="!cities">
                                    <p>Top Searched Cities: </p>
                                    <p [style.font-size]="'12px'" *ngFor="let cityId of allStats?.searchStats.topSearchedCityIds">
                                        {{getCityFromId(cityId)}}
                                    </p>
                                </span>
                                <span [hidden]="cities">
                                    <p>Loading Top Cities... </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6">
            <div class="card">
                <div class="content">
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="icon-big icon-info text-center">
                                <i class="ti-money"></i>
                            </div>
                        </div>
                        <div class="col-xs-9">
                            <div class="numbers">
                                <p>Total Purchase Count: {{ allStats?.purchaseStats.totalPurchaseCount }}</p>
                                <p>Purchase Amount (24hrs): ${{ allStats?.purchaseStats.totalPurchaseAmount }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6">
            <div class="card">
                <div class="content">
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="icon-big icon-info text-center">
                                <i class="ti-email"></i>
                            </div>
                        </div>
                        <div class="col-xs-9">
                            <div class="numbers">
                                <p>Total Emails Sent: {{ allStats?.emailStats.totalSentCount }}</p>
                                <p>Last 24 Hours:</p>
                                <p *ngFor="let emailType of getEmailTypes()">{{emailType}}: {{allStats?.emailStats.sentCounts[emailType]}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6">
            <div class="card">
                <div class="content">
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="icon-big icon-info text-center">
                                <i class="ti-alert"></i>
                            </div>
                        </div>
                        <div class="col-xs-9">
                            <div class="numbers">
                                <p>Total Notifications Sent: {{ allStats?.notificationStats.totalNotificationCount }}</p>
                                <p>Last 24 Hours:</p>
                                <p *ngFor="let notificationType of getNotificationTypes()">{{notificationType}}: {{allStats?.notificationStats.notificationCounts[notificationType]}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
