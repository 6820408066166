
export class PreferenceObject {
    id: string;
    title: string;
    type: string;

    constructor(id, title, type) {
        this.id = id;
        this.title = title;
        this.type = type;
    }
}
