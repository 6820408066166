import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationResponse } from 'models/authenticationResponse';
import { AuthenticateChallengeResponse } from 'models/authenticateChallengeResponse';
import { AuthenticationResult } from 'models/authenticationResult';
import { AbstractService } from './AbstractService.service';
import { JSONP_ERR_NO_CALLBACK } from '@angular/common/http/src/jsonp';
import { ApiRouteService } from './ApiRouteService';

(window as any).global = window;

@Injectable()
export class AuthService extends AbstractService {
    refreshing = false;

    constructor(
        public router: Router,
        protected http: HttpClient, private apiRouteService: ApiRouteService) {
        super(http);
    }

    public getSessionStatus(token: string): Observable<any> {
        const url = this.apiRouteService.getUrl('dashboard', `get-session-status?Token=${token}`);
        return this.http.get<any>(url, {headers: this.headers()});
    }

    public authenticateUser(username: string, password: string) {
        const creds = {
            'username': username,
            'password': password
        };
        const url = this.apiRouteService.getUrl('dashboard', 'authenticate-user');
        return this.http.post<AuthenticateChallengeResponse>(url, creds, {headers: this.headers()});
    }

    public authenticateNewPassword(username: string, password: string, session: string, srpa: string) {
        const url = this.apiRouteService.getUrl('dashboard', 'authenticate-new-password');

        const creds = {
            'username': username,
            'password': password,
            'session': session,
            'srpa': srpa
        };
        return this.http.post<AuthenticateChallengeResponse>(url, creds, {headers: this.headers()});
    }

    public refreshSession(auth: AuthenticationResult) {
        console.log('refreshing the session');
        this.refreshing = true;
        const url = this.apiRouteService.getUrl('dashboard', 'refresh-session');
        // console.log(JSON.stringify(auth, null, 2));
        return this.http.post<AuthenticationResult>(url, auth, {headers: this.headers()});
    }

    public attemptAuthentication(callback): void {

        const authSession = new AuthenticationResult();
        authSession.accessToken = this.getAccessToken();
        authSession.idToken = this.getIdToken();
        authSession.refreshToken = this.getRefreshToken();

        this.refreshSession(authSession).subscribe((authedSession) => {
            this.setSession(authedSession);
            console.log('attemptAuthentication succeeded');
            callback(true);
        }, (error) => {
            console.log('refreshSession error:', error);
            callback(false);
        });

    }

    public setSession(session): void {
        // Set the time that the Access Token will expire at
        console.log('session: ', session);
        const expiresAt = JSON.stringify((session.ExpiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', session.AccessToken);
        localStorage.setItem('id_token', session.IdToken);
        localStorage.setItem('expires_at', expiresAt);
        if (session.RefreshToken != null) {
            localStorage.setItem('refresh_token', session.RefreshToken);
        }
        localStorage.setItem('token_type', session.TokenType);
    }

    public logout(): void {
        // Remove tokens and expiry time from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_type');
        // Go back to the home route
        this.router.navigate(['/login']);
    }

    public isAuthenticated(): Observable<boolean> {
        // Check whether the current time is past the
        // Access Token's expiry time
        console.log('check if access token is expired');
        this.refreshing = false;

        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        // const expiresAt = (new Date()).getTime();
        const accessToken = this.getAccessToken();

        return Observable.create(observer => {
                if (expiresAt && accessToken) {
                    if (new Date().getTime() < expiresAt) {
                        this.getSessionStatus(accessToken).subscribe(_ => {
                            console.log('valid accessToken');
                            observer.next(true);
                        }, (error) => {
                            console.log('getSessionStatus error:', error);
                            observer.next(false);
                        });
                    } else {
                        console.log('access token is expired');
                        this.attemptAuthentication((success: boolean) => {
                                observer.next(success);
                                if (!success) {
                                    console.log('error refreshing');
                                }
                            }
                        );
                    }
                } else {
                    console.log('no access token set');
                    observer.next(false);
                }
            }
        );
    }

    public getAccessToken(): string {
        return localStorage.getItem('access_token');
    }

    public getIdToken(): string {
        return localStorage.getItem('id_token');
    }

    public getRefreshToken(): string {
        return localStorage.getItem('refresh_token');
    }

}
