import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ExplorePlansService } from 'services/ExplorePlans.service';
import { v4 as uuid } from 'uuid';
import { Image } from '../../../models/image';

@Component({
    selector: 'app-image-upload-preview',
    templateUrl: './image-upload-preview.component.html',
    styleUrls: ['./image-upload-preview.component.scss']
})
export class ImageUploadPreviewComponent {
    image: Image;
    @Output() imageChanged = new EventEmitter();
    @Input() componentClass: any;

    constructor(private explorePlansService: ExplorePlansService) {
        this.image = {
            url: 'assets/img/notfound.png',
            fileName: '',
            uuid: uuid()
        };
    }

    reset() {
        this.image = {
            url: 'assets/img/notfound.png',
            fileName: '',
            uuid: uuid()
        };
        this.imageChanged.emit(this.image);
    }

    preview(files) {
        if (files.length === 0) {
            return;
        }

        const file = files[0];

        const mimeType = file.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
            this.image = {
                url: reader.result as string,
                fileName: file.name,
                uuid: uuid()
            };
            this.imageChanged.emit(this.image);
        };
    }

}
