<div class="col-xs-12">
  <h2>New Password Required</h2>
</div>
<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
  <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="username">Username</label>
      <input type="text" formControlName="username" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.username.errors }"  />
    </div>
    <div class="form-group">
      <label for="password">New Password</label>
      <input type="password" formControlName="password" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
    </div>
    <div class="form-group">
      <label for="password">Repeat New Password</label>
      <input type="password" formControlName="passwordRepeat" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.passwordRepeat.errors }" />
    </div>
    <div class="row" *ngIf="error">
      <div class="col-xs-12">
        <p class="text-danger">{{error}}</p>
      </div>
    </div>
    <div class="form-group">
      <button [disabled]="loading" class="btn btn-primary">Change Password <i *ngIf="loading" class="fa fa-fw fa-circle-o-notch fa-spin"></i></button>
      &nbsp;<button routerLink="/login" (click)="$event.preventDefault()" class="btn btn-default">Go back</button>
    </div>
  </form>