import { Component, OnInit } from '@angular/core';
import { AuthService } from 'services/AuthService.service';

declare var $: any;

export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    {path: 'dashboard', title: 'Dashboard', icon: 'ti-panel', class: ''},
    {path: 'explore-plans', title: 'Explore Plans', icon: 'ti-bolt', class: ''},
    {path: 'images', title: 'Images', icon: 'ti-camera', class: ''},
    {path: 'promotions', title: 'Promotions', icon: 'ti-cup', class: ''},
    {path: 'api-docs', title: 'API Documentation', icon: 'ti-book', class: ''},
    {path: 'api-versions', title: 'API Versions', icon: 'ti-write', class: ''},
    {path: 'designs', title: 'Designs', icon: 'ti-palette', class: ''},
    {path: 'notifications', title: 'Notifications', icon: 'ti-alert', class: ''},
    {path: 'price-drop', title: 'Price Drop Finder', icon: 'ti-ticket', class: ''},
    {path: 'link-generator', title: 'Link Generator', icon: 'ti-share', class: ''},
    {path: 'monitor', title: 'Monitoring', icon: 'fa fa-fw fa-heartbeat', class: ''},
    {path: 'map', title: 'Map', icon: 'ti-map-alt', class: ''}
];

@Component({
    moduleId: module.id,
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];

    constructor(public auth: AuthService) {
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    isNotMobileMenu() {
        return $(window).width() <= 991;
    }

    logout() {
        this.auth.logout();
    }

}
