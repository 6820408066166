
<div class="container-fluid">
    <div class="row">
        <div class="btn-group col-md-4" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-lg btn-primary dropdown-toggle"
                    aria-controls="dropdown-basic">{{getSelectedImageTypeTitle()}}<span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="imageTypeSelected('ImageType_Category')">Categories ({{categories.length}})</a></li>
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="imageTypeSelected('ImageType_City')">Cities ({{cities.length}})</a></li>
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="imageTypeSelected('ImageType_EventType')">Types ({{eventTypes.length}})</a></li>
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="imageTypeSelected('ImageType_Performer')">Active Performers ({{activePerformers.length}})</a></li>
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="imageTypeSelected('ImageType_AllPerformer')">All Performers (6+) ({{allPerformers.length}})</a></li>
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="imageTypeSelected('ImageType_Venue')">Venues ({{venues.length}})</a></li>
            </ul>
        </div>
    </div>
    <div class="row" [hidden]="!selectedId" style="vertical-align: top; padding-bottom: 10px">
        <mat-card class="m-4" style="width: 90%">
            <mat-card-title>
                Images For {{getSelectedListItem()?.name}}
            </mat-card-title>
            <button mat-button color="primary" (click)="refreshButtonTapped()">Refresh</button>
            <mat-progress-spinner color="accent" mode="indeterminate" *ngIf="showLoadingSpinner"></mat-progress-spinner>
            <mat-grid-list cols="5" rowHeight="1:1.25" style="margin: 10px" *ngIf="!showLoadingSpinner">
                <mat-grid-tile *ngFor="let image of selectedImages" class="m-4">
                    <mat-card class="m-4 mat-elevation-z4">
                        <img mat-card-image [src]="getSmallImageUrl(image.urls)" alt="null" style="margin-bottom: 0">
                        <mat-card-actions>
                            <button mat-icon-button (click)="deleteImage(image)"
                                [disabled]="selectedImages.length <= 1">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </mat-grid-tile>
                <mat-grid-tile class="m-4">
                    <mat-card class="m-4 mat-elevation-z4">
                        <mat-card-subtitle style="horiz-align: center">Upload Image:</mat-card-subtitle>
                        <app-image-upload-preview (imageChanged)="handleImageAdded($event)"></app-image-upload-preview>
                    </mat-card>
                </mat-grid-tile>
                <mat-grid-tile class="m-4">
                    <mat-card class="m-4 mat-elevation-z4">
                        <mat-card-subtitle style="horiz-align: center">Add Image From Url:</mat-card-subtitle>
                        <mat-form-field>
                            <label>
                                <input matInput [(ngModel)]="imageUrl" placeholder="Url">
                            </label>
                        </mat-form-field>
                        <button mat-button color="primary" (click)="addImageFromUrl()">Add From Url</button>
                    </mat-card>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card>
    </div>

    <div class="row mt-3" [hidden]="!listItems">
        <mat-form-field class="mat-input-element">
            <input matInput placeholder="Filter" [(ngModel)]="searchText" (change)="searchTextChanged()">
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Go To Page Number" [(ngModel)]="pageNumber" class="goToPage">
        </mat-form-field>
        <button class="goButton" mat-stroked-button (click)="goToPage()">Go</button>
        <mat-paginator [length]="listItems?.length" [pageSize]="50" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
        <mat-table class="lessons-table mat-elevation-z8" [dataSource]="tableDataSource" matSort>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                <mat-cell *matCellDef="let item">{{item.name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="imageCount">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Image Count</mat-header-cell>
                <mat-cell *matCellDef="let item">{{item.imageCount < 0 ? '...' : item.imageCount}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="thumbnails">
                <mat-header-cell *matHeaderCellDef>Thumbnails</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div *ngFor="let image of item.images">
                        <img [src]="getThumbImageUrl(image.urls)" alt="null" style="margin-bottom: 0">
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['name', 'imageCount', 'thumbnails']"></mat-header-row>

            <mat-row *matRowDef="let row; columns: ['name', 'imageCount', 'thumbnails']"
                [ngClass]="{'mat-menu-item-highlighted': selectedId === row.id}" (click)="selectRow(row)"></mat-row>

        </mat-table>
    </div>
</div>
