import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DashboardService } from 'services/Dashboard.service';
import { ApiVersion } from 'models/apiVersion';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { asProviderData } from '@angular/core/src/view';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-api-versions',
    templateUrl: './api-versions.component.html',
    styleUrls: ['./api-versions.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class ApiVersionsComponent implements OnInit {
    public api_platforms = ['API_Web', 'API_Dashboard', 'API_iOS', 'API_Android'];
    apiVersions: ApiVersion[] = [];
    newVersionForm: FormGroup;
    submitting: boolean;
    error: string;
    math: Math;

    constructor(
        private api: DashboardService, private fb: FormBuilder, public toastr: ToastrService) {
    }

    ngOnInit() {
        this.getApiVersions();
        this.newVersionForm = this.fb.group({
            minVersion: [''],
            confirmVersion: ['', [Validators.required]],
            newCurrent: [''],
        });
    }

    getApiVersions(): void {
        this.api_platforms.forEach(element => {
            this.api.getApiVersions(element).subscribe(apiVersions => {
                this.apiVersions.push(apiVersions);
                this.apiVersions.sort( (a, b) => a.api.localeCompare(b.api));
            }, (err) => {
                console.log(err);
            });
        });
    }

    refreshData(): void {
        this.apiVersions = [];
        this.getApiVersions();
    }

    onSubmit(apiVersion): void {
        this.submitting = true;
        const thisVersion = apiVersion;
        const formValue = this.newVersionForm.value;
        thisVersion.minimumVersion = +formValue.minVersion;
        thisVersion.version = +formValue.newCurrent;

        if ((formValue.minVersion !== formValue.confirmVersion) || (formValue.newCurrent < formValue.minVersion)) {
            alert('Invalid Versions');
            this.toastr.error('Error Creating New Version');
            this.submitting = false;
            this.refreshData();
        } else {

            this.api.createApiVersion(thisVersion).subscribe(res => {
                console.log('New API Version Created');
                this.toastr.success('New Promotion Saved', 'Success!');
                this.submitting = false;
            }, err => {
                this.submitting = false;
                this.error = err.error ? err.error.message : err.message;
            });
            this.refreshData();
        }


    }
}
