import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/delay';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import * as Ladda from 'ladda';
import { LaddaButton } from 'ladda';

import SwaggerUI from 'swagger-ui';
import { DashboardService } from '../../services/Dashboard.service';
import '../shared/observable-extensions';
import { ApiVersion } from '../../models/apiVersion';
import { ApiDocumentLinksResponse } from '../../models/apiDocumentLinksResponse';


@Component({
    selector: 'app-api-docs',
    templateUrl: './api-docs.component.html',
    styleUrls: ['./api-docs.component.scss']
})
export class ApiDocsComponent implements OnInit, AfterViewInit {

    docLinks: ApiDocumentLinksResponse = null;
    selectedType = '';
    loading = true;
    alias = '';
    platformVersions: Map<string, ApiVersion[]>;
    platforms: string[];
    availableVersions: string[];
    availableRoutes: string[];
    selectedPlatform: string;
    selectedVersion = '';
    selectedRoute = '';
    private btnPostman: LaddaButton;

    constructor(public toastr: ToastrService, private el: ElementRef, private dashboardService: DashboardService) {
    }

    ngOnInit() {
        this.btnPostman = Ladda.create(document.querySelector('#btn-postman'));
    }

    ngAfterViewInit() {
        this.loading = true;
        this.dashboardService.getAllApiVersions().subscribe((apiVersions) => {
            this.loading = false;
            console.log(apiVersions);
            this.platformVersions = apiVersions;
            this.platforms = Object.keys(apiVersions);
            this.selectedPlatform = this.platforms[0];
            this.platformSelected();
        }, (e) => {
            this.showError(e);
        });

        this.dashboardService.getDocumentationLinks().subscribe((docLinks) => {
            this.docLinks = docLinks;
        }, (e) => {
            this.showError(e);
        });
    }

    platformSelected() {
        this.availableVersions = this.platformVersions[this.selectedPlatform].map(v => v.version.toString());
        this.selectedVersion = this.availableVersions[0];
        this.versionSelected();
    }

    versionSelected() {
        const selectedVersion = this.platformVersions[this.selectedPlatform].find(v => v.version.toString() === this.selectedVersion);
        this.availableRoutes = Object.keys(selectedVersion.routes);
    }

    routeSelected() {
        let docPlatform = this.selectedPlatform;
        if (docPlatform === 'API_Android' || docPlatform === 'API_iOS') {
            docPlatform = 'API_Mobile';
        }
        const selectedVersion = this.platformVersions[this.selectedPlatform].find(v => v.version.toString() === this.selectedVersion);
        const alias = selectedVersion.routes[this.selectedRoute];
        console.log(this.selectedPlatform);
        console.log(this.docLinks);
        const swaggerUrl = this.docLinks[docPlatform].find(d => d.service === this.selectedRoute).urls[alias];
        this.showDocsFromLink(swaggerUrl);
    }

    showError(e: HttpErrorResponse) {
        console.log(e);
        this.toastr.error(e.status + ' ' + e.statusText, 'An Error Occurred');
    }

    showDocsFromLink(link: string) {
        // noinspection JSUnusedLocalSymbols
        const ui = SwaggerUI({
            url: link,
            domNode: this.el.nativeElement.querySelector('.swagger-container'),
            deepLinking: true,
            filter: true,
            presets: [
                SwaggerUI.presets.apis
            ],
            docExpansion: 'none',
        });
    }

    getDocsForType(type: string) {
        this.selectedType = type;
        if (this.docLinks[type] === null) {
            this.toastr.info('Resetting the alias to default');
            this.toastr.error(type + ' Docs don\'t exist on this alias');
            this.dashboardService.getDocumentationLinks().subscribe((docLinks) => {
                console.log(docLinks);
                this.docLinks = docLinks;
                this.loading = false;
            }, (e) => {
                this.showError(e);
            });
        } else {
            this.showDocsFromLink(this.docLinks[type].swagger);
        }
    }

    copyToClipboard(link: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = link;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.toastr.success('Link Copied to Clipboard');
    }

    getPostmanDocs() {
        this.dashboardService.getPostmanLink(this.selectedPlatform, this.selectedVersion)
            .laddaSubscribe(this.btnPostman, (postmanUrl) => {
            const url = JSON.parse(postmanUrl);
            this.copyToClipboard(url);
        }, error => {
            console.error(error);
        });
    }
}

