import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-designs',
  templateUrl: './designs.component.html',
  styleUrls: ['./designs.component.scss']
})
export class DesignsComponent implements OnInit {

  designLinks = [
    {'title': 'Account', 'link': 'https://xd.adobe.com/spec/c4dfccf3-3b12-4ef9-4df4-bb7a17158ce3-b2ee/grid'},
    {'title': 'Crews', 'link': 'https://xd.adobe.com/spec/b9247b3f-fbc3-47dd-6976-c32606bae20b-cf15/grid'},
    {'title': 'Explore', 'link': 'https://xd.adobe.com/spec/321900a6-619f-4472-5344-ebd0f27549e0-4428/grid'},
    {'title': 'Launch', 'link': 'https://xd.adobe.com/view/e1298a64-eac0-4c04-6155-8536115194e2-9718/grid'},
    {'title': 'Planning', 'link': 'https://xd.adobe.com/spec/b1759de2-b46b-4dc6-4d3c-534f0189f5ac-c744/grid'},
    {'title': 'Promotional', 'link': 'https://xd.adobe.com/spec/fd1e7629-b386-4d6e-4471-aaebf8b0a76a-8b2f/grid'},
    {'title': 'Styles', 'link': 'https://xd.adobe.com/spec/be267739-d155-4f21-5d1c-19eeb58945b8-c817/grid'},
    {'title': 'Web', 'link': 'https://xd.adobe.com/spec/85dc973c-d664-4288-51d0-3894e84d8e39-f3ed/grid'},
    {'title': 'Stays', 'link': 'https://xd.adobe.com/view/037885a2-cb7f-408a-5a9f-35800c8a815e-7549/grid'},
    {'title': 'Restaurants', 'link': 'https://xd.adobe.com/view/5058f3a3-dfef-4f54-4a06-b8e6f33b738e-2669/grid'},
    {'title': 'Groups', 'link': 'https://xd.adobe.com/spec/b9247b3f-fbc3-47dd-6976-c32606bae20b-cf15/grid'},
    {'title': 'Events', 'link': 'https://xd.adobe.com/view/cc555518-13e9-43db-7902-17aec4623818-ba64/grid'},
    {'title': 'Search', 'link': 'https://xd.adobe.com/view/5c42a1e4-5099-4ea0-7cbd-cd09ba12b976-2a73/grid'},
    {'title': 'Nightlife', 'link': 'https://xd.adobe.com/view/4995c2db-d640-4341-4b5b-c767fc9a795b-3fa4/grid'},
    {'title': 'Notifications', 'link': 'https://xd.adobe.com/view/0080537e-e3f6-4967-499e-9f81904726da-76b1/grid'},
  ];

  constructor() { }

  ngOnInit() {
    console.log(this.designLinks);
  }

  goToUrl(url: string) {
    window.open(url, '_blank');
  }

}
