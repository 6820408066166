import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../../services/NotificationService.service';
import { NotificationSummary } from '../../../models/NotificationSummary';
import { MatSort, MatTableDataSource } from '@angular/material';

@Component({
    selector: 'app-notification-summaries',
    templateUrl: './notification-summaries.component.html',
    styleUrls: ['./notification-summaries.component.scss']
})
export class NotificationSummariesComponent implements OnInit {

    summaries: NotificationSummary[] = [];
    summariesDataSource = new MatTableDataSource<NotificationSummary>();
    showLoadingSpinner = false;
    hideDelivered = false;

    @ViewChild(MatSort) sort: MatSort;

    constructor(public bsModalRef: BsModalRef,
                public toastr: ToastrService,
                private notificationService: NotificationService) {
    }

    ngOnInit() {
        this.summariesDataSource.sort = this.sort;
        this.fetchNotificationSummaries();
    }

    fetchNotificationSummaries() {
        this.showLoadingSpinner = true;
        this.notificationService.getNotificationSummaries().subscribe(results => {
            this.showLoadingSpinner = false;
            this.summaries = results;
            this.summariesDataSource.data = results;
        }, error => {
            this.showLoadingSpinner = false;
            this.toastr.error(error.message);
            console.log(error);
        });
    }

    deleteNotification(summary: NotificationSummary) {
        this.showLoadingSpinner = true;
        this.notificationService.deleteNotification(summary).subscribe(result => {
                console.log(result);
                this.toastr.success('Scheduled Notification Deleted');
                this.fetchNotificationSummaries();
            }, error => {
            this.showLoadingSpinner = false;
                console.log(error);
                this.toastr.error(error.message, 'Error Deleting Notification');
            }
        );
    }

    updateDeliveredFilter() {
        if (this.hideDelivered) {
            this.summariesDataSource.data = this.summaries.filter(i => i.deliveredTimestamp === 0);
        } else {
            this.summariesDataSource.data = this.summaries;
        }
    }
}
