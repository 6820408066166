import { BsDatepickerConfig, formatDate } from 'ngx-bootstrap';

export class Utils {
    static formatDate(date: Date): string {
        return formatDate(date, 'YYYY-MM-DD');
    }

    static convertDateToUnix(date: Date): number {
        return Math.round(date.getTime() / 1000);
    }

    static getBSDateConfig(): BsDatepickerConfig {
        const config =  new BsDatepickerConfig();
        config.showWeekNumbers = false;
        config.customTodayClass = 'bs-custom-today-class';
        config.dateInputFormat = 'YYYY-MM-DD';
        config.containerClass = 'theme-default';
        return config;
    }
}
