import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Stats } from 'models/stats';
import { ApiVersion } from 'models/apiVersion';
import { AbstractService } from './AbstractService.service';
import { Suburb } from '../models/Suburb';
import { ApiDocumentLinksResponse } from '../models/apiDocumentLinksResponse';
import { ApiRouteService } from './ApiRouteService';

@Injectable()

export class DashboardService extends AbstractService {

    constructor(
        protected http: HttpClient, private apiRouteService: ApiRouteService) {
        super(http);
    }

    public getDashboardStats(startDate: number, endDate: number): Observable<Stats> {
        const url = this.apiRouteService.getUrl('dashboard', 'get-stats');
        const params = {StartTime: startDate.toString(), EndTime: endDate.toString()};
        return this.http.get<Stats>(url, {params: params, headers: this.headers()});
    }

    public getPostmanLink(platform: string, version: string): Observable<string> {
        let endpointWithParams = `get-presigned-postman`;
        endpointWithParams = `${endpointWithParams}?Api=${platform}`;
        if (version && version.length > 0) {
            endpointWithParams = `${endpointWithParams}&Version=${version}`;
        }
        const url = this.apiRouteService.getUrl('dashboard-docs', endpointWithParams);
        return this.http.get<string>(url, {headers: this.headers(), responseType: 'text' as 'json'});
    }

    public getDocumentationLinks(): Observable<ApiDocumentLinksResponse> {
        const url = this.apiRouteService.getUrl('dashboard-docs', 'get-presigned-docs');
        return this.http.get<any>(url, {headers: this.headers()});
    }

    public getAllApiVersions(): Observable<Map<string, ApiVersion[]>> {
        const url = this.apiRouteService.getUrl('dashboard-docs', 'get-api-versions');
        return this.http.get<Map<string, ApiVersion[]>>(url, {headers: this.headers()});
    }

    public getApiVersions(platform: string): Observable<ApiVersion> {
        const url = `${this.serviceRouterUrl}minimum-version`;
        return this.http.get<ApiVersion>(url, {headers: this.platformServiceRouterHeaders(platform)});
    }

    public createApiVersion(newVersion: ApiVersion): Observable<ApiVersion> {
        return this.http.post<ApiVersion>(
            `${this.serviceRouterUrl}new-version`,
            newVersion,
            { headers: this.serviceRouterHeaders('service-router') });
    }

    public getSuburbs(cityId: string): Observable<Suburb[]> {
        const url = this.apiRouteService.getUrl('dashboard', 'get-city-suburbs');
        const params = {cityId: cityId};
        return this.http.get<Suburb[]>(url, {params: params, headers: this.priceDropHeaders('dashboard')});
    }
}
