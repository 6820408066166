import { Injectable } from '@angular/core';
import { AbstractService } from './AbstractService.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TimeSeriesData, TimeSeriesRequest } from '../models/TimeSeriesRequest';
import { TraceSummaryRequest, TraceSummaryResponse } from '../models/TraceSummaryRequest';
import { environment } from '../environments/environment';
import { ApiRouteService } from './ApiRouteService';

@Injectable()
export class MonitorService extends AbstractService {

    constructor(protected http: HttpClient, private apiRouteService: ApiRouteService) {
        super(http);
    }

    public getTimeSeriesStats(request: TimeSeriesRequest): Observable<Map<string, TimeSeriesData[]>> {
        const url = this.apiRouteService.getUrl('dashboard-monitoring', 'get-time-series-statistics');
        return this.http.post<Map<string, TimeSeriesData[]>>(url, request, {headers: this.headers()});
    }

    public getTraceSummary(request: TraceSummaryRequest): Observable<TraceSummaryResponse> {
        const url = this.apiRouteService.getUrl('dashboard-monitoring', 'get-trace-summary');
        return this.http.post<TraceSummaryResponse>(url, request, {headers: this.headers()});
    }
}
