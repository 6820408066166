import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { City } from '../../models/city';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NotificationService } from '../../services/NotificationService.service';
import { ExplorePlansService } from '../../services/ExplorePlans.service';
import { ToastrService } from 'ngx-toastr';
import { MatSort, MatTableDataSource } from '@angular/material';
import { EventTicketPrices, Price } from '../../models/EventTicketPrices';
import { PriceDropEventComponent } from './price-drop-event/price-drop-event.component';
import { DashboardService } from '../../services/Dashboard.service';
import { forkJoin, Observable } from 'rxjs';
import { concatAll, distinct, flatMap, map, max, toArray } from 'rxjs/operators';
import { Utils } from '../shared/utils';

@Component({
    selector: 'app-price-drop',
    templateUrl: './price-drop.component.html',
    styleUrls: ['./price-drop.component.scss']
})
export class PriceDropComponent implements OnInit, AfterViewInit {

    bsModalRef: BsModalRef;
    cities: City[];
    selectedCities: City[];
    searchType = 'TicketPriceType_MinPriceRelativeTo7DayAvg';
    comparisonValue: number = -0.25;
    resultsDataSource = new MatTableDataSource<EventTicketPrices>();
    noResults = false;
    loading = false;
    querySinceDate: Date;
    dateConfig = Utils.getBSDateConfig();

    @ViewChild(MatSort) sort: MatSort;

    constructor(private modalService: BsModalService,
                private notificationService: NotificationService,
                private dashboardService: DashboardService,
                private explorePlansService: ExplorePlansService,
                public toastr: ToastrService) {
    }

    ngOnInit() {
        this.initializeAppData();
    }

    ngAfterViewInit(): void {
        this.resultsDataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'eventName':
                    return item.event.eventName;
                case 'minPrice':
                    return item.event.minPrice;
                case 'date':
                    return item.event.eventDateString;
                case 'ticketCount':
                    return item.event.ticketCount;
                case 'city':
                    return this.cityNameForId(item.event.cityId);
                case 'priceDrop':
                    return this.getPriceDropForEvent(item);
                case 'priceDropPercent':
                    return this.getPriceDropPercentForEvent(item);
                default:
                    return item[property];
            }
        };
        this.resultsDataSource.sort = this.sort;
    }

    initializeAppData() {
        this.explorePlansService.getCities().subscribe(cities => {
            this.cities = cities.map(c => {
                (c as any).label = c.city + ', ' + c.state;
                return c;
            });
        });
    }

    query() {
        let queryAfterTimestamp = 0;
        if (this.querySinceDate != null) {
            queryAfterTimestamp = this.querySinceDate.toUnix();
        }

        this.loading = true;
        this.getAllSuburbCityIdsForQuery().subscribe(cityIds => {
            this.notificationService.getPriceDropEvents(
                cityIds.join(','),
                this.comparisonValue,
                this.searchType,
                queryAfterTimestamp)
                .subscribe(results => {
                    console.log(results);
                    if (results != null) {
                        this.resultsDataSource.data = this.addCurrentResultsToPriceData(results);
                    } else {
                        this.resultsDataSource.data = [];
                    }
                    this.noResults = this.resultsDataSource.data.length === 0;
                    this.loading = false;
                }, error => {
                    console.log(error);
                    this.toastr.error(error.message);
                    this.resultsDataSource.data = [];
                    this.noResults = false;
                    this.loading = false;
                });
        });
    }

    addCurrentResultsToPriceData(priceData: EventTicketPrices[]): EventTicketPrices[] {
        priceData.forEach( eventPriceData => {
            const newPrice = new Price();
            newPrice.minPrice = eventPriceData.event.minPrice;
            newPrice.ticketCount = eventPriceData.event.ticketCount;
            newPrice.listingCount = eventPriceData.event.listingCount;
            newPrice.eventId = eventPriceData.event.id;
            newPrice.maxPrice = eventPriceData.event.maxPrice;
            newPrice.maxPrice = eventPriceData.event.maxPrice;
            newPrice.timestamp = new Date().toUnix();
            eventPriceData.prices.push(newPrice);
        });
        return priceData;
    }

    cityNameForId(id: string): string {
        const city = this.cities.find(c => c.id === id);
        if (city != null) {
            return city.city + ' ' + city.state;
        }
        return '';
    }

    getPriceDropForEvent(eventPrices: EventTicketPrices): number {
        const highValue = Math.max(...eventPrices.prices.map(p => p.minPrice));
        const currentMin = eventPrices.event.minPrice;
        return highValue - currentMin;
    }

    getPriceDropPercentForEvent(eventPrices: EventTicketPrices): number {
        const highValue = Math.max(...eventPrices.prices.map(p => p.minPrice));
        const currentMin = eventPrices.event.minPrice;
        return (highValue - currentMin) / highValue;
    }

    showEventModal(eventPrices: EventTicketPrices) {
        this.bsModalRef = this.modalService.show(PriceDropEventComponent,
            {
                class: 'modal-lg',
                initialState:
                    {
                        eventTicketPrices: eventPrices,
                        cityName: this.cityNameForId(eventPrices.event.cityId)
                    }
            });
    }

    getAllSuburbCityIdsForQuery(): Observable<any> {
        return forkJoin(this.selectedCities.map(c => this.dashboardService.getSuburbs(c.id)))
            .pipe(concatAll(), flatMap(x => x), map(y => y.id), distinct(), toArray());
    }
}

