// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    dashboardUrl: 'https://c9zpgtwvi4.execute-api.us-west-2.amazonaws.com/staging/',
    serviceRouterUrl: 'https://yft3wp8uv9.execute-api.us-west-2.amazonaws.com/staging/',
    exploreDashboardUrl: 'https://42ohy3761b.execute-api.us-west-2.amazonaws.com/staging/',
    imageDashboardUrl: 'https://72e0mpcjqd.execute-api.us-west-2.amazonaws.com/staging/',
    directoryUrl: 'https://yft3wp8uv9.execute-api.us-west-2.amazonaws.com/staging/',
    citiesUrl: 'https://c9zpgtwvi4.execute-api.us-west-2.amazonaws.com/staging/',
    suburbsUrl: 'https://c9zpgtwvi4.execute-api.us-west-2.amazonaws.com/staging/',
    mapUrl: 'https://30b3n2ccj4.execute-api.us-west-2.amazonaws.com/staging/'
};
