<div class="container-fluid">
    <div class="row">
        <div class="btn-group col-md-4" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-lg btn-primary dropdown-toggle"
                    aria-controls="dropdown-basic">Add New Explore Plan<span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="openModal(ExplorePlanType.Planning)">Planning Type</a></li>
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="openModal(ExplorePlanType.Performer)">Performer Type</a></li>
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="openModal(ExplorePlanType.CustomPerformer)">Custom Performer Type</a></li>
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="openModal(ExplorePlanType.City)">City Type</a></li>
                <li role="menuitem"><a role="button" class="dropdown-item" (click)="openModal(ExplorePlanType.Categories)">Categories Type</a></li>
            </ul>
        </div>
    </div>

    <p></p>

    <div class="row" [hidden]="!explorePlans">
        <mat-slide-toggle [(ngModel)]="hideExpired" (change)="getExplorePlans()" >Hide Expired Explore Plans</mat-slide-toggle>
        <p></p>
        <mat-table class="lessons-table mat-elevation-z8" [dataSource]="explorePlans">
            <ng-container matColumnDef="image">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let item"><img [src]="item.imageUrl" alt="null" style="margin-bottom: 0">
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                <mat-cell *matCellDef="let item">{{item.description}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                <mat-cell *matCellDef="let item">{{item.action}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="expiry">
                <mat-header-cell *matHeaderCellDef>Expiry Date</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{getFormattedPlanExpiryDate(item)}}
                    <mat-icon *ngIf="getPlanExpired(item)" color="warn">warning</mat-icon>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <button mat-button (click)="deleteExplorePlan(item)">
                        Delete Explore Plan
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['image', 'description', 'type', 'expiry', 'delete']"></mat-header-row>

            <mat-row *matRowDef="let row; columns: ['image', 'description', 'type', 'expiry', 'delete']"></mat-row>

        </mat-table>
    </div>

</div>
