import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    MatPaginatorModule,
    MatTableModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatListModule,
    MatGridListModule,
    MatIconModule, MatProgressSpinnerModule, MatChipsModule, MatSlideToggleModule, MatTooltipModule, MatRadioModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule
} from '@angular/material';
import {MatSortModule} from '@angular/material/sort';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { SidebarModule } from './sidebar/sidebar.module';

import { NavbarModule } from './shared/navbar/navbar.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ExplorePlansComponent } from './explore-plans/explore-plans.component';

import { LoginComponent } from './login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthService } from '../services/AuthService.service';
import { ExplorePlansService } from '../services/ExplorePlans.service';
import { HttpClient, HttpHandler, HttpClientModule } from '@angular/common/http';
import { DashboardService } from 'services/Dashboard.service';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { Pipe } from '@angular/core';

import { AddPerformerModalComponent } from './explore-plans/add-performer-modal/add-performer-modal.component';
import { LocalStorageService } from 'services/LocalStorage.service';
import { AddCityModalComponent } from './explore-plans/add-city-modal/add-city-modal.component';
import { AddPlanningModalComponent } from './explore-plans/add-planning-modal/add-planning-modal.component';
import { AddCategoriesModalComponent } from './explore-plans/add-categories-modal/add-categories-modal.component';
import { ImageUploadPreviewComponent } from './explore-plans/image-upload-preview/image-upload-preview.component';
import { CustomPerformerModalComponent } from './explore-plans/custom-performer-modal/custom-performer-modal.component';
import { AuthGuard } from './guards/auth-guard.service';
import { NewPasswordComponent } from './login/new-password/new-password.component';
import { ImagesComponent } from './images/images.component';
import { ImagesService } from '../services/Images.service';
import { ApiDocsComponent } from './api-docs/api-docs.component';
import { Buffer } from 'buffer';
import { PromotionsComponent } from './promotions/promotions.component';
import { PromotionService } from '../services/Promotion.service';
import { AccordionModule, AlertModule, BsDropdownModule, TimepickerModule, TooltipModule } from 'ngx-bootstrap';
import { AddPromotionModalComponent } from './promotions/add-promotion-modal/add-promotion-modal.component';
import { ApiVersionsComponent } from './api-versions/api-versions.component';
import { DesignsComponent } from './designs/designs.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationService } from '../services/NotificationService.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OwlDateTime } from 'ng-pick-datetime/date-time/date-time.class';
import { NotificationSummariesComponent } from './notifications/notification-summaries/notification-summaries.component';
import { PriceDropComponent } from './price-drop/price-drop.component';
import { PriceDropEventComponent } from './price-drop/price-drop-event/price-drop-event.component';
import { LinkGeneratorComponent } from './link-generator/link-generator.component';
import { MonitorComponent } from './monitor/monitor.component';
import { MonitorService } from '../services/Monitor.service';
import { ApiRouteService } from '../services/ApiRouteService';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map/map.component';

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        ExplorePlansComponent,
        LoginComponent,
        AddPerformerModalComponent,
        AddCityModalComponent,
        AddPlanningModalComponent,
        AddCategoriesModalComponent,
        ImageUploadPreviewComponent,
        CustomPerformerModalComponent,
        NewPasswordComponent,
        ImagesComponent,
        ApiDocsComponent,
        PromotionsComponent,
        AddPromotionModalComponent,
        ApiVersionsComponent,
        DesignsComponent,
        NotificationsComponent,
        NotificationSummariesComponent,
        PriceDropComponent,
        PriceDropEventComponent,
        LinkGeneratorComponent,
        MonitorComponent,
        MapComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, {useHash: true}),
        SidebarModule,
        NavbarModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ReactiveFormsModule,
        NgSelectModule,
        FormsModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatInputModule,
        MatCardModule,
        MatButtonModule,
        MatListModule,
        MatGridListModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        AccordionModule.forRoot(),
        TimepickerModule.forRoot(),
        OwlNativeDateTimeModule,
        OwlDateTimeModule,
        MatTooltipModule,
        MatRadioModule,
        MatCheckboxModule,
        AlertModule.forRoot(),
        CommonModule,
        BsDropdownModule.forRoot()
    ],
    providers: [
        ApiRouteService,
        AuthService,
        AuthGuard,
        ExplorePlansService,
        DashboardService,
        LocalStorageService,
        ImagesService,
        PromotionService,
        HttpClient,
        NotificationService,
        MonitorService,
        MatDatepickerModule
    ],
    entryComponents: [
        ImageUploadPreviewComponent,
        AddPerformerModalComponent,
        AddCityModalComponent,
        AddCategoriesModalComponent,
        AddPlanningModalComponent,
        CustomPerformerModalComponent,
        AddPromotionModalComponent,
        NotificationSummariesComponent,
        PriceDropEventComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
