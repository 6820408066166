<div class="container-fluid">
  <div class="row">
    <label class="switch">
      <input (click)="toggleMarker('masterCity')" type="checkbox" checked>
      <span class="slider round"></span>
    </label>
    <label>Master Cities</label>
    <label class="switch">
      <input (click)="toggleMarker('suburb')" type="checkbox" checked>
      <span class="slider round"></span>
    </label>
    <label>Suburbs</label>
    <label class="switch">
      <input (click)="toggleMarker('city')" type="checkbox" checked>
      <span class="slider round"></span>
    </label>
    <label>Cities</label>
    <label class="switch">
      <input (click)="toggleMarker('user')" type="checkbox" checked>
      <span class="slider round"></span>
    </label>
    <label>Users</label>
    <label class="switch">
      <input (click)="toggleMarker('searchedCity')" type="checkbox" checked>
      <span class="slider round"></span>
    </label>
    <label>Searched Cities</label>
    <div class="row">
      <div class="col-12">
        <div #mapContainer id="map"></div>
      </div>
      <div class="col-3">
        <div id="legend">
          <div>
            <img class="legendIcon" src="../../assets/img/masterCityPin.png">
            <p class="legendName">Master City</p>
          </div>
          <div>
            <img class="legendIcon" src="../../assets/img/suburbPin.png">
            <p class="legendName">Suburb</p>
          </div>
          <div>
            <img class="legendIcon" src="../../assets/img/defaultPin.png">
            <p class="legendName">City</p>
          </div>
          <div>
            <img class="legendIcon" src="../../assets/img/searchedCityPin.png">
            <p class="legendName">Searched City</p>
          </div>
          <div>
            <img class="legendIcon" src="../../assets/img/personPin.png">
            <p class="legendName">Person</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <mat-form-field>
  <mat-label>User Timestamp</mat-label>
  <input matInput [matDatepicker]="picker1" [formControl]="date">
  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
  <mat-datepicker #picker1></mat-datepicker>
  <button class="btn-primary" (click)="getUsers()">Search</button>
</mat-form-field> -->

<div class="container-fluid">
  <div class="row">
    <div class="col-3">
      <h5>Overlapping Cities</h5>
      <div class="card">
        <div class="content">
          <div class="row">
            <div *ngFor='let city of overLappingCities' class="col-xs-9">
              <p>Name: {{city.city}}, {{city.state}}</p>
              <p>Lat, Lng: {{city.lat}}, {{city.lng}}</p>
              <p>Id: {{city.id}}</p>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>