import { Injectable } from '@angular/core';
import { AbstractService } from './AbstractService.service';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './LocalStorage.service';
import { CreateExplorePlan } from '../models/createExplorePlan';
import { Observable } from 'rxjs';
import { EventType } from '../models/eventType';
import { Venue } from '../models/venue';
import { VenuePage } from '../models/venuePage';
import { PerformerPage } from '../models/performerPage';
import { Performer } from '../models/performer';
import 'rxjs-compat/add/operator/concatMap';
import 'rxjs-compat/add/operator/concat';
import 'rxjs-compat/add/operator/startWith';
import { KrugoImage } from '../models/KrugoImage';
import { ResponseContentType } from '@angular/http';
import * as buffer from 'buffer';
import { subscribeOn } from 'rxjs/operators';
import { Scheduler } from 'rxjs-compat';
import { ApiRouteService } from './ApiRouteService';

@Injectable({
    providedIn: 'root'
})

@Injectable()
export class ImagesService extends AbstractService {

    constructor(protected http: HttpClient, private localStorageService: LocalStorageService, private apiRouteService: ApiRouteService) {
        super(http);
    }

    public getEventTypes(): Observable<EventType[]> {
        const url = this.apiRouteService.getUrl('dashboard', 'get-event-types');
        return this.localStorageService.observableHttpRequest<EventType[]>('event-types', url, this.headers());
    }

    private getVenuesPage(lastEvaluatedId: string, lastEvaluatedCityId: string): Observable<VenuePage> {
        let params = {lastEvaluatedId: lastEvaluatedId, lastEvaluatedCityId: lastEvaluatedCityId};
        if (!lastEvaluatedCityId) {
            params = null;
        }
        const url = this.apiRouteService.getUrl('dashboard', 'get-venues');
        return this.http.get<VenuePage>(url, {headers: this.headers(), params: params});
    }

    public getVenuesFromApi(lastEvaluatedId: string, lastEvaluatedCityId: string): Observable<VenuePage> {
        return this.getVenuesPage(lastEvaluatedId, lastEvaluatedCityId).concatMap((page) => {
            if (!page.lastEvaluatedId) {
                return new Observable<VenuePage>(o => o.next(page));
            } else {
                return new Observable<VenuePage>(o => o.next(page)).concat(() => {
                    return this.getVenuesFromApi(page.lastEvaluatedId, page.lastEvaluatedCityId);
                });
            }
        });
    }

    public getVenues(): Observable<Venue[]> {
        return new Observable<Venue[]>(observer => {
            const localVenues = LocalStorageService.getItem('venues');
            if (localVenues == null) {
                const venues: Venue[] = [];
                this.getVenuesFromApi(null, null).subscribe(page => {
                    venues.push(...page.objects);
                    if (!page.lastEvaluatedCityId) {
                        const expiry = new Date();
                        expiry.setHours(expiry.getHours() + 8);
                        try {
                            LocalStorageService.setItem('venues', venues, expiry);
                        } catch (e) {
                            console.log(e);
                        }
                        observer.next(venues);
                    }
                });
            } else {
                observer.next(localVenues);
            }
        });
    }

    private getAllPerformersPage(lastEvaluatedId: string, MinimumPriority: string, ReturnInactive: string): Observable<PerformerPage> {
        const params = {lastEvaluatedId: lastEvaluatedId, MinimumPriority: MinimumPriority, ReturnInactive: ReturnInactive};
        if (lastEvaluatedId === '') {
            lastEvaluatedId = '';
            MinimumPriority = '6';
            ReturnInactive = 'true';
        }
        const url = this.apiRouteService.getUrl('dashboard', 'get-performers');
        return this.http.get<PerformerPage>(url, {headers: this.headers(), params: params});

    }

    public getAllPerformersFromApi(lastEvaluatedId: string, MinimumPriority: string, ReturnInactive: string): Observable<PerformerPage> {
        return this.getAllPerformersPage(lastEvaluatedId, MinimumPriority, ReturnInactive).concatMap((page) => {
            if (page.lastEvaluatedId === '') {
                return new Observable<PerformerPage>(o => o.next(page));
            } else {
                return this.getAllPerformersFromApi(page.lastEvaluatedId, '6', 'true').startWith(page);
            }
        });
    }

    public getAllPerformers(): Observable<Performer[]> {
        console.log('hitting getAllPerformers');
        return new Observable<Performer[]>(observer => {
            const localPerformers = LocalStorageService.getItem('performers');
            if (localPerformers == null) {
                const allPerformers: Performer[] = [];
                this.getAllPerformersFromApi('', '6', 'true').subscribe(page => {
                    allPerformers.push(...page.objects);
                    if (!page.lastEvaluatedId) {
                        const expiry = new Date();
                        expiry.setHours(expiry.getHours() + 8);
                        try {
                            LocalStorageService.clean(allPerformers);
                            LocalStorageService.setItem('performers', allPerformers, expiry);
                        } catch (e) {
                            console.log(e);
                        }
                        observer.next(allPerformers);
                    }
                });
            } else {
                observer.next(localPerformers);
            }
        });
    }

    public getImages(imageIds: string[]): Observable<Map<string, KrugoImage[]>> {
        const params = {ids: imageIds.join(',')};
        const url = this.apiRouteService.getUrl('dashboard-image', 'get-image-urls');
        return this.http.get<Map<string, KrugoImage[]>>(url, {headers: this.headers(), params: params});
    }

    public getImageUploadUrl(fileName: string, imageId: string, imageType: string): Observable<any> {
        const regex = new RegExp(/[^A-Za-z0-9]+/g);
        const adjustedfileName = fileName.replace(' ', '').toLowerCase();
        const fileExtension = adjustedfileName.split('.').pop();
        let filteredFileName = adjustedfileName.replace(regex, '');
        filteredFileName = filteredFileName.slice(0, -fileExtension.length);
        filteredFileName = filteredFileName + '.' + fileExtension;
        const url = this.apiRouteService.getUrl('dashboard-image', 'get-image-upload-url');
        return this.http.get<any>(url,
            {
                headers: this.headers(),
                params: {FileName: filteredFileName, ImageId: imageId, ImageType: imageType}
            });
    }

    public getImage(imageUrl: string): Observable<Blob> {
        return this.http.get(imageUrl, {responseType: 'blob', observe: 'response'}).map((res) => res.body);
    }

    public putImageUploadUrl(url: string, file: string, fileName: string) {
        const adjustedfileName = fileName.replace(' ', '').toLowerCase();
        let type = '';
        switch (adjustedfileName.split('.').pop()) {
            case 'jpg':
            case 'jpeg':
                type = 'image/jpeg';
                break;
            case 'png':
                type = 'image/png';
                break;
        }

        // const buff = new Buffer(file.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        const newFileName = file.replace(/^data:image\/\w+;base64,/, '');
        const buff = buffer.Buffer.from(newFileName, 'base64');
        const headers = new HttpHeaders({
            'Content-Type': type,
            'Content-Encoding': 'base64'
        });

        const blob = new Blob([new Uint8Array(buff)]);
        return this.http.put<any>(url, blob, {headers});
    }

    public putImageBlob(url: string, blob: Blob): Observable<any> {
        const type = blob.type;
        const headers = new HttpHeaders({
            'Content-Type': type,
            'Content-Encoding': 'base64'
        });

        return this.http.put<any>(url, blob, {headers});
    }

    public deleteImage(imageId: string, md5Hash: string): Observable<any> {
        const url = this.apiRouteService.getUrl('dashboard-image', 'delete-image');
        return this.http.delete(url,
            {
                headers: this.headers(),
                params: {MD5Hash: md5Hash, Id: imageId},
                responseType: 'text'
            });
    }
}
