import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'services/AuthService.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  session: string;
  srpa: string;
  username: string;
  submitted: boolean;
  loading: boolean;
  passwordForm: FormGroup;
  error: string;

  constructor(private auth: AuthService, private formBuilder: FormBuilder, private router: Router) {
    try {
      this.username = this.router.getCurrentNavigation().extras.state.username
      this.session = this.router.getCurrentNavigation().extras.state.session
      this.srpa = this.router.getCurrentNavigation().extras.state.srpa
      if (!this.session || !this.srpa) {
        this.router.navigate(['login'])
      }
    } catch {
      this.router.navigate(['login'])
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.passwordForm.controls; }

  ngOnInit() {

    this.passwordForm = this.formBuilder.group({
      username: [{value:this.username, disabled:true}, Validators.required],
      password: ['', Validators.required],
      passwordRepeat: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }

    if (this.passwordForm.value.password !== this.passwordForm.value.passwordRepeat) {
      this.error = "Passwords do not match."
      return
    }

    this.loading = true;
    this.auth.authenticateNewPassword(this.f.username.value, this.f.password.value, this.session, this.srpa)
      .subscribe(
        data => {
          this.auth.setSession(data.AuthenticationResult)
          this.router.navigate(['dashboard']);
        },
        error => {
          console.log(error)
          this.error = error.error ? error.error.message : error.message
          this.loading = false;
        });
  }

}
