export class ScheduleNotificationsRequest {
    deliveryPriority: DeliveryPriority;
    deliverAfterTimestamp: number;
    deliverBeforeTimestamp: number;
    userIds: string[];
    deviceIds: string[];
    performerIds: string[];
    cityIds: string[];
    eventIds: string[];
    startDate: string;
    endDate: string;
    isDefaultNotification: boolean;
    showInNotificationCenter: boolean;
    message: string;
    subject: string;
    notificationAction: string;
}

export enum DeliveryPriority {
    ASAP_High = 'DeliveryPriority_ASAP_High',
    ASAP_Low = 'DeliveryPriority_ASAP_Low',
    Scheduled_Marketing = 'DeliveryPriority_Scheduled_Marketing',
    Scheduled_Transactional = 'DeliveryPriority_Scheduled_Transactional',
}
