import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '../../services/NotificationService.service';
import { City } from '../../models/city';
import { ExplorePlansService } from '../../services/ExplorePlans.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationDeploymentGroup, NotificationUser } from '../../models/notificationDeploymentGroup';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PreferenceObject } from '../../models/preferenceObject';
import { Utils } from '../shared/utils';
import { Performer } from '../../models/performer';
import { BsModalRef, BsModalService, startOf } from 'ngx-bootstrap';
import { GetEventsForPlanDto } from '../../models/getEventsForPlanDto';
import { DeliveryPriority, ScheduleNotificationsRequest } from '../../models/ScheduleNotificationRequest';
import { NotificationSummariesComponent } from './notification-summaries/notification-summaries.component';
import '../shared/extensions';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, AfterViewInit {

    allPreferences: PreferenceObject[] = [];
    dateConfig = Utils.getBSDateConfig();

    selectedFindUsersSection = '';
    cities: City[];

    queryCity: City;
    queryCityRadiusKm = 50;
    queryCitySinceDate: Date;
    queryAfterDate: Date;
    queryBeforeDate: Date;
    updatedSinceDate: Date;

    queryPreferenceObject: PreferenceObject;
    pendingQueryDescription: string;
    queryLoading = false;
    getDeviceIds = false;
    queryResults: NotificationUser[];
    activePerformerLookupDataSource = new MatTableDataSource<Performer>();
    activePerformerLookupResults: Performer[] = null;
    activePerformerLookupLoading = false;

    deploymentGroupsTableDataSource = new MatTableDataSource<NotificationDeploymentGroup>();
    deploymentGroups: NotificationDeploymentGroup[] = [];
    notificationSubject: string;
    notificationMessage: string;
    notificationDeliverAfter: Date = new Date();
    notificationDeliverBefore: Date = new Date();
    saveToNotificationCenter = false;
    notificationSearchStartDate: Date;
    notificationSearchEndDate: Date;
    notificationSearchCities: any;
    notificationSearchEventsResults: any[] = [];
    notificationSearchPerformers: Performer[] = [];
    notificationSearchSelectedPerformers: Performer[] = [];
    notificationSearchEventsLoading = false;
    notificationSearchSelectedEvents: any[] = [];
    notificationSelectedPriority = DeliveryPriority.Scheduled_Marketing;
    notificationPriorities = [DeliveryPriority.ASAP_High, DeliveryPriority.ASAP_Low, DeliveryPriority.Scheduled_Transactional,
        DeliveryPriority.Scheduled_Marketing];
    notificationScheduling = false;
    notificationType = 'default';
    bsModalRef: BsModalRef;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private modalService: BsModalService,
                private notificationService: NotificationService,
                private explorePlansService: ExplorePlansService,
                public toastr: ToastrService) {
    }

    ngOnInit() {
        this.initializeAppData();
    }

    ngAfterViewInit() {
        this.activePerformerLookupDataSource.sort = this.sort;
        this.activePerformerLookupDataSource.paginator = this.paginator;
        this.notificationDeliverAfter = new Date();
        this.notificationDeliverBefore = new Date();
        this.notificationDeliverBefore.setDate(this.notificationDeliverBefore.getDate() + 30);
    }

    initializeAppData() {
        this.explorePlansService.getCities().subscribe(cities => {
            this.cities = cities.map(c => {
                (c as any).label = c.city + ', ' + c.state;
                return c;
            });

            const cityPreferences = cities.map(c => new PreferenceObject(c.id, (c as any).label + ' (City)', 'City'));
            this.allPreferences = this.allPreferences.concat(cityPreferences);
        });

        this.explorePlansService.getActivePerformers().subscribe(performers => {
            const performerPrefs = performers.map(p => new PreferenceObject(p.id, p.name + ' (Performer)', 'Performer'));
            this.allPreferences = this.allPreferences.concat(performerPrefs);
            this.notificationSearchPerformers = performers;
        }, e => {
            this.toastr.error(e.message);
        });

        this.explorePlansService.getCategories().subscribe(categories => {
            const catPrefs = categories.map(c => new PreferenceObject(c.id, c.name + ' (Category)', 'Category'));
            this.allPreferences = this.allPreferences.concat(catPrefs);
        }, e => {
            this.toastr.error(e.message);
        });
    };

    switchToQuerySection(section: string) {
        this.selectedFindUsersSection = section;
        this.resetQuery();
    }

    queryUsersForCity() {
        if (!this.queryCity) {
            this.toastr.info('Select a city first');
            return;
        }

        this.pendingQueryDescription = 'Near ' + (this.queryCity as any).label + ' - ' + this.queryCityRadiusKm + 'km';

        let preferenceId = null;
        if (this.queryPreferenceObject) {
            preferenceId = this.queryPreferenceObject.id;
            this.pendingQueryDescription = this.pendingQueryDescription + ', Preference: ' + this.queryPreferenceObject.title;
        }

        let timestamp = null;
        console.log(this.queryCitySinceDate);
        if (this.queryCitySinceDate) {
            const date = new Date(this.queryCitySinceDate);
            timestamp = date.toUnix();
            this.pendingQueryDescription = this.pendingQueryDescription + ', Since: ' + Utils.formatDate(date);
        }

        this.queryLoading = true;
        this.notificationService.getUsersForLocation(this.queryCity.lat,
            this.queryCity.lng, this.queryCityRadiusKm * 1000,
            preferenceId, timestamp)
            .subscribe((results) => {
                this.queryLoading = false;
                this.queryResults = results;
                if (!results || results.length === 0) {
                    this.toastr.info('No Results');
                }
            }, (error) => {
                this.queryLoading = false;
                this.toastr.error(error.toString());
            });
    }

    queryUsersWithPreference() {
        if (!this.queryPreferenceObject) {
            this.toastr.info('Select a preference');
            return;
        }
        this.pendingQueryDescription = 'Preference: ' + this.queryPreferenceObject.title;

        this.queryLoading = true;
        this.notificationService.getUsersWithPreference(this.queryPreferenceObject.id)
            .subscribe((results) => {
                this.queryLoading = false;
                this.queryResults = results;
                if (!results || results.length === 0) {
                    this.toastr.info('No Results');
                }
            }, (error) => {
                this.queryLoading = false;
                this.toastr.error(error.toString());
            });
    }

    queryUsersWithHomeCity() {
        if (!this.queryCity) {
            this.toastr.info('Select a home city');
            return;
        }
        this.pendingQueryDescription = 'Home City: ' + (this.queryCity as any).label;

        let preferenceId = null;
        if (this.queryPreferenceObject) {
            preferenceId = this.queryPreferenceObject.id;
            this.pendingQueryDescription = this.pendingQueryDescription + ', Preference: ' + this.queryPreferenceObject.title;
        }

        this.queryLoading = true;
        this.notificationService.getUsersWithHomeCity(this.queryCity.id, preferenceId)
            .subscribe((results) => {
                this.queryLoading = false;
                this.queryResults = results;
                if (!results || results.length === 0) {
                    this.toastr.info('No Results');
                }
            }, (error) => {
                this.queryLoading = false;
                this.toastr.error(error.toString());
            });
    }

    queryUsersWitLastSessionDates() {
        if (!this.queryBeforeDate || !this.queryAfterDate) {
            this.toastr.info('Select dates');
            return;
        }

        const afterDate = new Date(this.queryAfterDate);
        const afterTimestamp = afterDate.toUnix();
        const beforeDate = this.queryBeforeDate;
        const beforeTimestamp = beforeDate.toUnix();
        this.pendingQueryDescription = 'Last Active: ' + Utils.formatDate(afterDate) + ' - ' + Utils.formatDate(beforeDate);
        if (this.getDeviceIds) {
            this.pendingQueryDescription = this.pendingQueryDescription + ' (Devices without accounts)';
        }

        this.queryLoading = true;
        this.notificationService.getUsersWithLastSessionDates(afterTimestamp, beforeTimestamp, this.getDeviceIds)
            .subscribe((results) => {
                this.queryLoading = false;
                this.queryResults = results;
                if (!results || results.length === 0) {
                    this.toastr.info('No Results');
                }
            }, (error) => {
                this.queryLoading = false;
                this.toastr.error(error.toString());
            });
    }

    findPerformersUpdatedSinceDate() {
        if (!this.updatedSinceDate) {
            this.toastr.info('Select date');
            return;
        }

        const date = startOf(new Date(this.updatedSinceDate), 'day');
        const timestamp = date.toUnix();
        this.activePerformerLookupLoading = true;
        this.notificationService.getPerformersRecentlyUpdated(timestamp)
            .subscribe((results) => {
                this.activePerformerLookupLoading = false;
                if (results.length > 0) {
                    this.activePerformerLookupResults = results;
                    this.activePerformerLookupDataSource.data = results;
                }
            }, (error) => {
                this.toastr.error(error.toString());
                this.activePerformerLookupLoading = false;
            });
    }

    activePerformerClicked(item: Performer) {
        this.queryPreferenceObject = this.allPreferences.find(i => i.id === item.id);
    }

    addResultsToDeploymentGroups() {
        const newDeploymentGroup = new NotificationDeploymentGroup(
            this.pendingQueryDescription,
            this.queryResults
        );
        this.deploymentGroups.push(newDeploymentGroup);
        this.deploymentGroupsTableDataSource.data = this.deploymentGroups;
        this.resetQuery();
    }

    removeFromDeploymentGroups(item: NotificationDeploymentGroup) {
        const index = this.deploymentGroups.indexOf(item, 0);
        if (index > -1) {
            this.deploymentGroups.splice(index, 1);
        }
        this.deploymentGroupsTableDataSource.data = this.deploymentGroups;
    }

    findEventsForNotification() {

        const selectedCities = this.getSelectedSearchCities();
        if (!this.notificationSearchStartDate || !this.notificationSearchEndDate || selectedCities.length !== 1) {
            this.toastr.info('Select dates and city.');
            return;
        }

        const dto: GetEventsForPlanDto = {
            startDate: Utils.formatDate(new Date(this.notificationSearchStartDate)),
            endDate: Utils.formatDate(new Date(this.notificationSearchEndDate)),
            cityId: selectedCities[0].id,
        };

        this.notificationSearchEventsLoading = true;
        this.explorePlansService.getEventsForPlan(dto).subscribe(events => {
            this.notificationSearchEventsResults = events;
            this.notificationSearchEventsLoading = false;
        }, () => {
            this.notificationSearchEventsResults = [];
            this.notificationSearchEventsLoading = false;
        });
    }

    sendNotifications() {
        if (this.notificationMessage.length === 0 || this.notificationSubject.length === 0) {
            this.toastr.info('Enter a subject and message.');
            return;
        }

        const userIds = this.deploymentGroups
            .flatMap(i => i.users)
            .filter(i => !i.isDeviceId)
            .map(i => i.id)
            .filter((v, i, a) => a.indexOf(v) === i);
        const deviceIds = this.deploymentGroups
            .flatMap(i => i.users)
            .filter(i => i.isDeviceId)
            .map(i => i.id)
            .filter((v, i, a) => a.indexOf(v) === i);
        const selectedCities = this.getSelectedSearchCities();
        const selectedEvents = this.getSelectedEvents();
        const eventIds = selectedEvents ? selectedEvents.map(i => i.id) : [];

        const scheduleNotificationsRequest = new ScheduleNotificationsRequest();
        scheduleNotificationsRequest.deliveryPriority = this.notificationSelectedPriority;
        scheduleNotificationsRequest.deliverAfterTimestamp = this.notificationDeliverAfter.toUnix();
        scheduleNotificationsRequest.deliverBeforeTimestamp = this.notificationDeliverBefore.toUnix();
        scheduleNotificationsRequest.userIds = userIds;
        scheduleNotificationsRequest.deviceIds = deviceIds;
        if (this.notificationSearchSelectedPerformers && this.notificationSearchSelectedPerformers.length > 0) {
            scheduleNotificationsRequest.performerIds = this.notificationSearchSelectedPerformers.map(p => p.id);
        }
        scheduleNotificationsRequest.cityIds = selectedCities.map(i => i.id);
        scheduleNotificationsRequest.eventIds = eventIds;
        scheduleNotificationsRequest.startDate = Utils.formatDate(this.notificationSearchStartDate);
        scheduleNotificationsRequest.endDate = Utils.formatDate(this.notificationSearchEndDate);
        scheduleNotificationsRequest.isDefaultNotification = this.notificationType === 'default';
        scheduleNotificationsRequest.showInNotificationCenter = this.saveToNotificationCenter;
        scheduleNotificationsRequest.message = this.notificationMessage;
        scheduleNotificationsRequest.subject = this.notificationSubject;

        if (this.notificationType === 'suggested-plan') {
            scheduleNotificationsRequest.notificationAction = 'Dashboard_SuggestedPlan';
        }

        if (this.notificationType === 'default' ||
            this.notificationType === 'event-details' ||
            this.notificationType === 'suggested-plan') {
            scheduleNotificationsRequest.cityIds = [];
            scheduleNotificationsRequest.performerIds = [];
            scheduleNotificationsRequest.startDate = null;
            scheduleNotificationsRequest.endDate = null;
        }
        console.log(scheduleNotificationsRequest);
        this.notificationScheduling = true;
        this.notificationService.scheduleNotifications(scheduleNotificationsRequest).subscribe(() => {
                this.toastr.success('Notifications Successfully Scheduled');
                this.notificationScheduling = false;
                this.resetQuery();
                this.resetNotificationContent();
            }, error => {
                this.notificationScheduling = false;
                this.toastr.error(error.toString());
            }
        );
    }

    resetQuery() {
        this.queryResults = null;
        this.queryCity = null;
        this.queryPreferenceObject = null;
        this.queryCitySinceDate = new Date();
        this.queryCitySinceDate.setMonth(this.queryCitySinceDate.getMonth() - 1);
        this.queryAfterDate = new Date();
        this.queryAfterDate.setMonth(this.queryAfterDate.getMonth() - 1);
        this.queryBeforeDate = new Date();
        this.updatedSinceDate = new Date();
        this.updatedSinceDate.setMonth(this.updatedSinceDate.getMonth() - 1);
        this.getDeviceIds = false;
        this.activePerformerLookupResults = null;
        this.activePerformerLookupLoading = false;
    }

    resetNotificationContent() {
        this.deploymentGroups = [];
        this.deploymentGroupsTableDataSource.data = [];
        this.notificationSubject = null;
        this.notificationMessage = null;
        this.notificationSearchEndDate = null;
        this.notificationSearchStartDate = null;
        this.notificationSearchCities = null;
        this.notificationSearchEventsResults = [];
        this.notificationSearchSelectedEvents = [];
        this.notificationSearchSelectedPerformers = [];
        this.notificationType = 'default';
    }

    getUniqueUsers(): string[] {
        // @ts-ignore
        return this.deploymentGroups.flatMap(i => i.users.flatMap(u => u.id)).filter((x, y, a) => a.indexOf(x) === y);
    }

    showSummariesModal() {
        this.bsModalRef = this.modalService.show(NotificationSummariesComponent, {class: 'modal-lg'});
    }

    getSelectedEvents(): any[] {
        let events = [];
        if (this.notificationSearchSelectedEvents && Array.isArray(this.notificationSearchSelectedEvents)) {
            events = this.notificationSearchSelectedEvents;
        } else {
            events = this.notificationSearchSelectedEvents ? [this.notificationSearchSelectedEvents] : [];
        }
        return events;
    }


    getSelectedSearchCities(): City[] {
        let c = [];
        if (this.notificationSearchCities && Array.isArray(this.notificationSearchCities)) {
            c = this.notificationSearchCities;
        } else {
            c = this.notificationSearchCities ? [this.notificationSearchCities] : [];
        }
        return c;
    }
}
