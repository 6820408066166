import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface CacheStorageRecord {
    expires: number;
    value: any;
}

@Injectable()
export class LocalStorageService {

    static setItem(key: string, value: any, expires: Date) {
        const cacheObject: CacheStorageRecord = {value, expires: expires.valueOf()};
        localStorage.setItem(key, JSON.stringify(cacheObject));
    }

    static getItem(key: string) {
        const item = localStorage.getItem(key);
        if (item !== null) {
            const cacheObject = JSON.parse(item);
            if (cacheObject.expires <= Date.now().valueOf()) {
                localStorage.removeItem(key);
                return null;
            }
            return cacheObject.value;
        }
        return null;
    }

    static deleteItem(key: string) {
        localStorage.removeItem(key);
    }

    constructor(private http: HttpClient) {
    }

    observableHttpRequest<T>(key: string, url: string, headers: any) {
        return new Observable<T>((observer) => {
            let item: T;
            item = LocalStorageService.getItem(key);
            if (item === null) {
                this.http.get<T>(url, {headers})
                    .subscribe((v) => {
                        const expiry = new Date();
                        // TODO: 8 hours for the workday but maybe configurable?
                        expiry.setHours(expiry.getHours() + 8);
                        try {
                            LocalStorageService.clean(v);
                            LocalStorageService.setItem(key, v, expiry);
                        } catch (e) {
                            console.log(e);
                        }
                        return observer.next(v);
                    });
            } else {
                return observer.next(item);
            }
        });
    }

    // Removes empty fields to reduce storage size
    // tslint:disable-next-line:member-ordering
    static clean(obj: any) {
        for (const propName in obj) {
            if (this.isArray(obj[propName])) {
                obj[propName].forEach((o) => {
                    this.clean(o);
                });
            }

            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        }
    }

    // tslint:disable-next-line:member-ordering
    static isArray (value) {
        return value && typeof value === 'object' && value.constructor === Array;
    }
}
