import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Stats } from 'models/stats';
import { ApiVersion } from 'models/apiVersion';
import { AbstractService } from './AbstractService.service';
import { Suburb } from '../models/Suburb';
import { ApiDocumentLinksResponse } from '../models/apiDocumentLinksResponse';
import { LocalStorageService } from './LocalStorage.service';

@Injectable()

export class ApiRouteService extends AbstractService {

    public routes: Map<string, string>;
    routesInitializedSubject = new BehaviorSubject<boolean>(false);

    constructor(protected http: HttpClient) {
        super(http);
        const localRoutes = localStorage.getItem('api_routes');
        if (localRoutes) {
            this.routes = JSON.parse(localRoutes);
            this.routesInitializedSubject.next(true);
        }
        this.getDashboardRoutes();
    }

    getDashboardRoutes() {
        const url = `${this.serviceRouterUrl}service-routes`;
        this.http.get<ApiVersion>(url, {headers: this.serviceRouterHeaders('service-router')}).subscribe(apiVersion => {
            this.routes = apiVersion.routes;
            if (!this.routes) {
                this.routesInitializedSubject.next(true);
            }
            localStorage.setItem('api_routes', JSON.stringify(this.routes));
        }, error => {
            console.log(error);
        });
    }

    getUrl(service: string, endpoint: string): string {
        return `${this.routes[service]}/${endpoint}`;
    }
}
