<h3>Design XD Links:</h3>
<mat-table [dataSource]="designLinks">
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef>Design</mat-header-cell>
    <mat-cell *matCellDef="let item" (click)="goToUrl(item.link)">{{item.title}}</mat-cell>
  </ng-container>

  <mat-row *matRowDef="let row; columns: ['title']"></mat-row>

</mat-table>
