import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddPerformerModalComponent } from './add-performer-modal/add-performer-modal.component';
import { AddCityModalComponent } from './add-city-modal/add-city-modal.component';
import { AddPlanningModalComponent } from './add-planning-modal/add-planning-modal.component';
import { AddCategoriesModalComponent } from './add-categories-modal/add-categories-modal.component';
import { CustomPerformerModalComponent } from './custom-performer-modal/custom-performer-modal.component';
import { ExplorePlansService } from '../../services/ExplorePlans.service';
import { ExplorePlan } from '../../models/explorePlan';
import { Utils } from '../shared/utils';
import { ToastrService } from 'ngx-toastr';

enum ExplorePlanType {
    Performer = 1,
    City,
    Planning,
    Categories,
    CustomPerformer
}

@Component({
    selector: 'app-explore-plans',
    templateUrl: './explore-plans.component.html',
    styleUrls: ['./explore-plans.component.scss']
})
export class ExplorePlansComponent implements OnInit {

    ExplorePlanType: typeof ExplorePlanType = ExplorePlanType;
    bsModalRef: BsModalRef;
    hideExpired: boolean;
    explorePlans: ExplorePlan[];

    constructor(private modalService: BsModalService, private explorePlansService: ExplorePlansService, public toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.getExplorePlans();
    }

    openModal(type: ExplorePlanType) {
        this.bsModalRef = this.modalService.show(explorePlanFactory(type), {class: 'modal-lg'});
        console.log(this.bsModalRef);
        this.bsModalRef.content.onPlanCreated.subscribe(() => {
            this.getExplorePlans();
        });
    }

    getFormattedPlanExpiryDate(explorePlan: ExplorePlan): string {
        return Utils.formatDate(new Date(explorePlan.expiryDate * 1000));
    }

    getPlanExpired(explorePlan: ExplorePlan): boolean {
        return new Date(explorePlan.expiryDate * 1000) <= new Date();
    }

    getExplorePlans() {
        this.explorePlansService.getCurrentExplorePlans(!this.hideExpired).subscribe((plans) => {
            this.explorePlans = plans.sort((a, b) => a.expiryDate - b.expiryDate);
        });
    }

    deleteExplorePlan(explorePlan: ExplorePlan) {
        this.explorePlansService.deleteExplorePlan(explorePlan.id, explorePlan.expiryDate).subscribe(() => {
            this.toastr.success('Explore Plan Deleted');
            this.getExplorePlans();
        }, (e) => {
            console.log(e);
            this.toastr.error(`Delete Failed: ${e.statusText}`);
        });
    }

}

function explorePlanFactory(type: ExplorePlanType) {
    switch (type) {
        case ExplorePlanType.Performer: {
            return AddPerformerModalComponent;
        }
        case ExplorePlanType.City: {
            return AddCityModalComponent;
        }
        case ExplorePlanType.Planning: {
            return AddPlanningModalComponent;
        }
        case ExplorePlanType.Categories: {
            return AddCategoriesModalComponent;
        }
        case ExplorePlanType.CustomPerformer: {
            return CustomPerformerModalComponent;
        }
    }
}
