import { AuthService } from '../../services/AuthService.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {


    constructor(private _authService: AuthService, private _router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const observer = this._authService.isAuthenticated();
        observer.subscribe(res => {
            if (!res) {
                this._router.navigate(['/login']);
            }
        });
        if (this._authService.refreshing) {
            console.log('refreshing the session not hitting isAuthenticated()');
        }
        return observer;
    }
}
