import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MonitorService } from '../../services/Monitor.service';
import { TimeSeriesData, TimeSeriesRequest } from '../../models/TimeSeriesRequest';
import { concat, EMPTY, from, merge, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { Chart } from 'chart.js';
import { concatMap, expand, toArray } from 'rxjs/operators';
import { TraceSummaryRequest, TraceSummaryResponse } from '../../models/TraceSummaryRequest';
import { DashboardService } from '../../services/Dashboard.service';

@Component({
    selector: 'app-monitor',
    templateUrl: './monitor.component.html',
    styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit {

    directory: string[] = [];
    showServiceSelection = false;
    healthPercentageString = '-';
    totalInvokesString = '-';
    allServiceResults: Map<string, TimeSeriesData[]> = new Map<string, TimeSeriesData[]>();
    serviceCycleTimer: Subscription = null;
    reloadPageTimer: Subscription = null;
    currentService = '';
    currentServiceEndpoints: string[] = null;
    currentTraceSummaryArray: TraceSummaryResponse[] = null;

    currentServiceTotalInvocations: number;
    currentServiceTotalOK: number;
    currentServiceTotalErrors: number;
    currentServiceTotalFaults: number;
    currentServiceAvgResponseTime: number;

    invokeChartObject: Chart = null;
    responseChartObject: Chart = null;

    loadingTimeSeriesData = true;
    loadingTraceData = false;

    @ViewChild('invokeChart') invokeChart: ElementRef;
    @ViewChild('responseTimeChart') responseTimeChart: ElementRef;
    private skipSubject: Subject<any>;

    static chunkArray<T>(array: T[], chunkSize: number): T[][] {
        const R = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            R.push(array.slice(i, i + chunkSize));
        }
        return R;
    }

    constructor(private monitorService: MonitorService, private dashboardService: DashboardService) {
    }

    ngOnInit() {
        this.dashboardService.getAllApiVersions().subscribe((apiVersions) => {
            this.directory = Object.keys(apiVersions['API_iOS'][0].routes);
            this.getStatsForEntireDirectory();
        }, e => {
            console.log(e);
        });
    }

    getStatsForEntireDirectory() {
        const chunked = MonitorComponent.chunkArray(this.directory, 5);
        const obs = [];
        chunked.forEach(chunk => {
            obs.push(this.getStatsForServices(chunk));
        });

        this.loadingTimeSeriesData = true;
        concat(...obs).pipe(toArray()).subscribe(d => {
            const combinedData = Object.assign({}, ...d);
            this.loadingTimeSeriesData = false;
            this.allServiceResults = combinedData;
            this.generateOverallHealthStats();
        });
    }

    generateOverallHealthStats() {
        this.getTotalHealthPercentage();
        this.createCharts(this.directory[0]);

        if (this.serviceCycleTimer != null) {
            this.serviceCycleTimer.unsubscribe();
        }

        let serviceIndex = 0;
        this.skipSubject = new Subject();
        const timerSubject = merge(timer(0, 10000), this.skipSubject);
        this.serviceCycleTimer = timerSubject.subscribe( () => {
            const directoryLength = this.directory.length;
            const displayService = this.directory[serviceIndex % directoryLength];
            serviceIndex++;
            this.displayServiceInfo(displayService);
        });

        if (this.reloadPageTimer != null) {
            this.reloadPageTimer.unsubscribe();
        }

        this.reloadPageTimer = timer(1000 * 10 * 60).subscribe( () => {
            window.location.reload();
        });
    }

    getTotalHealthPercentage() {
        let totalInvokes = 0;
        let okInvokes = 0;
        let totalFaults = 0;
        const servicesWithFaults = [];

        const serviceNames = Array.from(Object.keys(this.allServiceResults));
        for (const key of serviceNames) {
            const serviceResults = this.allServiceResults[key] as TimeSeriesData[];
            totalInvokes += serviceResults.reduce((sum, current) => sum + current.TotalCount, 0);
            okInvokes += serviceResults.reduce((sum, current) => sum + current.OkCount, 0);
            const serviceFaults = serviceResults.reduce((sum, current) => sum + current.FaultStatistics.TotalCount, 0);
            totalFaults += serviceFaults;
            if (serviceFaults > 0) {
                servicesWithFaults.push(key);
            }
        }

        console.log('has faults: ', ...servicesWithFaults);
        this.healthPercentageString = Math.ceil(
            ((serviceNames.length - servicesWithFaults.length) / serviceNames.length) * 100
        ).toString() + '%';
        this.totalInvokesString = totalInvokes.toString();
    }

    displayServiceInfo(serviceName: string, manualSelected: boolean = false) {
        this.currentService = serviceName;
        if (this.allServiceResults[this.currentService].length === 0 && !manualSelected) {
            this.skipToNexService();
            return;
        }
        // this.getTraceSummaryData(serviceName);
        this.getTraceSummary(serviceName);
        this.createCharts(serviceName);
        this.getTotalledStatsForCurrentService();
    }

    skipToNexService() {
        this.skipSubject.next(null);
    }

    getTotalledStatsForCurrentService() {
        this.currentServiceTotalOK = 0;
        this.currentServiceTotalErrors = 0;
        this.currentServiceTotalFaults = 0;
        this.currentServiceAvgResponseTime = 0;
        const serviceData = this.allServiceResults[this.currentService];

        this.currentServiceTotalInvocations = serviceData.reduce((sum, current) => sum + current.TotalCount, 0);
        this.currentServiceTotalOK = serviceData.reduce((sum, current) => sum + current.OkCount, 0);
        this.currentServiceTotalErrors = serviceData.reduce((sum, current) => sum + current.ErrorStatistics.TotalCount, 0);
        this.currentServiceTotalFaults = serviceData.reduce((sum, current) => sum + current.FaultStatistics.TotalCount, 0);
        const responseTimeSum = serviceData.reduce((sum, current) => sum + current.AverageResponseTime, 0);
        this.currentServiceAvgResponseTime = Math.round(responseTimeSum / (serviceData.length) * 100) / 100;
    }

    getStatsForServices(serviceNames: string[]): Observable<Map<string, TimeSeriesData[]>> {
        const request = new TimeSeriesRequest();
        request.serviceNames = serviceNames;
        request.endTime = (new Date()).toUnix();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        request.startTime = startDate.toUnix();
        request.timePeriod = 300;
        request.maxNumPages = 50;
        return this.monitorService.getTimeSeriesStats(request);
    }

    getTraceSummary(serviceName: string) {
        this.loadingTraceData = true;
        this.getTraceSummaryPage(serviceName, null).pipe(
            expand(({nextKey}) => (nextKey ? this.getTraceSummaryPage(serviceName, nextKey) : EMPTY)),
            toArray()
        ).subscribe(traceSummaries => {
            this.loadingTraceData = false;
            this.currentTraceSummaryArray = traceSummaries;
            this.currentServiceEndpoints = Array.from(new Set(traceSummaries.flatMap(i => Object.keys(i.traceSummaryMap))));
        }, error => {
            console.log(error);
        });
    }

    getTraceSummaryPage(serviceName: string, pageKey: string): Observable<TraceSummaryResponse> {
        const traceSummaryRequest = new TraceSummaryRequest();
        traceSummaryRequest.serviceName = serviceName;
        traceSummaryRequest.nextKey = pageKey;
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        traceSummaryRequest.startTime = startDate.toUnix();
        traceSummaryRequest.endTime = (new Date()).toUnix();
        return this.monitorService.getTraceSummary(traceSummaryRequest);
    }

    createCharts(service: string) {
        if (this.invokeChartObject) {
            this.invokeChartObject.destroy();
        }

        if (this.responseChartObject) {
            this.responseChartObject.destroy();
        }

        const ctx = this.invokeChart.nativeElement.getContext('2d');
        this.invokeChartObject = new Chart(ctx, {
            type: 'bar',
            data: {
                datasets: this.getDatasetsFromResults(service)
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        type: 'time'
                    }],
                    yAxes: [{
                        id: 'totalCount',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });

        const ctx2 = this.responseTimeChart.nativeElement.getContext('2d');
        this.responseChartObject = new Chart(ctx2, {
            type: 'bar',
            data: {
                datasets: this.getResponseDatasetsFromResults(service)
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        type: 'time'
                    }],
                    yAxes: [{
                        id: 'totalCount',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    getDatasetsFromResults(serviceName: string): any[] {
        const datasets = [];
        const e = {
            label: 'Non OK',
            data: this.getSortedNonOKTotalCount(this.allServiceResults[serviceName], serviceName),
            borderWidth: 1,
            barThickness: 3,
            maxBarThickness: 20,
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            borderColor: '#FA4545',
            pointBackgroundColor: '#FA4545',
            backgroundColor: '#FA4545',
            fill: true,
            yAxisID: 'totalCount',
            lineTension: 0,
            showLine: true
        };
        datasets.push(e);

        const d = {
            label: 'OK',
            data: this.getSortedTotalCount(this.allServiceResults[serviceName], serviceName),
            borderWidth: 1,
            barThickness: 10,
            maxBarThickness: 20,
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            borderColor: '#FFCE00',
            pointBackgroundColor: '#FFCE00',
            backgroundColor: '#FFCE00',
            fill: true,
            yAxisID: 'totalCount',
            lineTension: 0,
            showLine: true
        };
        datasets.push(d);

        return datasets;
    }

    getResponseDatasetsFromResults(serviceName: string): any[] {
        const datasets = [];
        const d = {
            label: 'Avg Response Time (s)',
            data: this.getSortedAverageResponseTimes(this.allServiceResults[serviceName], serviceName),
            borderWidth: 1,
            barThickness: 'flex',
            maxBarThickness: 20,
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            borderColor: '#007FFF',
            pointBackgroundColor: '#007FFF',
            backgroundColor: '#007FFF',
            fill: true,
            yAxisID: 'totalCount',
            lineTension: 0,
            showLine: true
        };
        datasets.push(d);

        return datasets;
    }

    getSortedTotalCount(serviceData: TimeSeriesData[], serviceName: string): any[] {
        return serviceData.sort(i => new Date(i.Timestamp).toUnix())
            .map(d => {
                return {'x': d.Timestamp, 'y': d.TotalCount, label: serviceName};
            });
    }

    getSortedNonOKTotalCount(serviceData: TimeSeriesData[], serviceName: string): any[] {
        return serviceData.sort(i => new Date(i.Timestamp).toUnix())
            .map(d => {
                return {'x': d.Timestamp, 'y': d.TotalCount - d.OkCount, label: serviceName};
            });
    }

    getSortedAverageResponseTimes(serviceData: TimeSeriesData[], serviceName: string): any[] {
        return serviceData.sort(i => new Date(i.Timestamp).toUnix())
            .map(d => {
                return {'x': d.Timestamp, 'y': d.AverageResponseTime, label: serviceName};
            });
    }

    getShortenedEndpointName(endpoint: string): string {
        return endpoint.substring(endpoint.lastIndexOf('/') + 1);
    }

    getHttpStatusCountForEndpoint(endpoint: string, range: number): string {
        const endRange = range + 99;
        const traces = this.currentTraceSummaryArray.flatMap(i => i.traceSummaryMap[endpoint]);
        const result: any = traces.filter(t => t.httpStatus >= range && t.httpStatus <= endRange).length;
        return result > 0 ? result : '';
    }

    getErrorStatusCountForEndpoint(endpoint: string): number {
        // return 1;
        const traces = this.currentTraceSummaryArray.flatMap(i => i.traceSummaryMap[endpoint]);
        return traces.filter(t => t.httpStatus >= 400 && t.httpStatus <= 599).length;
    }

    serviceSelected() {
        this.reloadPageTimer.unsubscribe();
        this.serviceCycleTimer.unsubscribe();
        this.displayServiceInfo(this.currentService, true);
    }

    toggleServiceSelection() {
        this.showServiceSelection = !this.showServiceSelection;
    }
}
