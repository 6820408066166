import { AfterViewInit, Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { ImagesService } from '../../services/Images.service';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/delay';
import { ToastrService } from 'ngx-toastr';
import { PromotionService } from '../../services/Promotion.service';
import { Promotion, Step } from '../../models/promotion';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AddPromotionModalComponent } from './add-promotion-modal/add-promotion-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';


@Component({
    selector: 'app-promotions',
    templateUrl: './promotions.component.html',
    styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit, AfterViewInit {

    loading = false;
    promotions: Promotion[] = [];
    imageUrls = new Map<string, string>();
    bsModalRef: BsModalRef;

    constructor(
        private modalService: BsModalService,
        private imagesService: ImagesService,
        private promotionService: PromotionService,
        public toastr: ToastrService,
        private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.getPromotions();
    }

    ngAfterViewInit() {

    }

    getPromotions() {
        this.loading = true;
        this.promotionService.getPromotions(false).subscribe(promos => {
            this.loadImages(promos);
        }, e => {
            this.loading = false;
            console.log(e);
        });
    }

    addPromoTapped() {
        this.bsModalRef = this.modalService.show(AddPromotionModalComponent, {class: 'modal-lg'});
        this.bsModalRef.content.onPromotionCreated.subscribe(() => {
            this.loading = true;
            // wait 3 seconds for uploaded images to populate in s3
            setTimeout(() => { this.getPromotions(); }, 3000);
        });
    }

    getSortedSteps(promo: Promotion): Step[] {
        return promo.steps.sort(((a, b) => a.priority - b.priority));
    }

    loadImages(promos: Promotion[]) {
        const imageIds = [];
        promos.forEach(p => {
            if (p.imageId.length > 0) {
                imageIds.push(p.imageId);
            }
            p.steps.forEach(s => {
                if (s.imageId.length > 0) {
                    imageIds.push(s.imageId);
                }
            });
        });

        this.imagesService.getImages(imageIds).subscribe((krugoImages) => {
            console.log(krugoImages);
            Object.keys(krugoImages).forEach(k => {
                const v = krugoImages[k];
                this.imageUrls[k] = v[0].urls.find(u => u.size === 'medium').url;
            });
            console.log(this.imageUrls);
            this.promotions = promos;
            this.loading = false;
        }, e => {
            console.log(e);
            this.loading = false;
        });
    }

    getImageUrl(imageId: string): SafeResourceUrl {
        if (imageId.length <= 0 || this.imageUrls[imageId] === null) {
            return null;
        }
        return this.sanitizer.bypassSecurityTrustStyle(`url(${this.imageUrls[imageId]})`);
    }
}
