
<div class="sidebar-wrapper">
    <div class="logo">
        <a href="/" class="simple-text">
            <div class="logo-img">
                <img src="../../assets/img/krugo-wordmark-black.svg" alt="">
            </div>
        </a>
    </div>
    <ul class="nav">
            <li class="divider" *ngIf="isNotMobileMenu()"></li>
        <li *ngFor="let menuItem of menuItems" routerLinkActive="active" class="{{menuItem.class}}">
            <a [routerLink]="[menuItem.path]">
                <i class="{{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
        <a *ngIf="auth.getAccessToken()" role="button" (click)="logout()" id="logoutLink">Logout</a>
    </ul>
</div>
