<div class="modal-header">
  <h4 class="modal-title pull-left">Add a Performer(s) Item</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">Cancel</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="addPerformerForm" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="description">Add a title</label>
          <input type="text" class="form-control" id="description" aria-describedby="description" formControlName="description"
            [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
        </div>

        <div class="form-group">
          <label for="expiryDate">Add an expiry date</label>
          <div class="input-group">
            <input type="text" placeholder="Datepicker" class="form-control" formControlName="expiryDate" bsDatepicker
              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
              [ngClass]="{ 'is-invalid': submitted && f.expiryDate.errors }">
            <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
          </div>
        </div>

        <div class="form-group">
          <label for="performerIds">Add performer IDs (1-5)</label>
          <ng-select #select [virtualScroll]="true" [items]="performers" [multiple]="true" [maxSelectedItems]="5" bindLabel="name" bindValue="id" [loading]="!performers" 
          formControlName="performerIds" [ngClass]="{ 'is-invalid': submitted && f.performerIds.errors }">
          </ng-select>
        </div>
      </div>
      <div class="col-md-6">
        <label for="file">Add an image</label><br/>
        <app-image-upload-preview [componentClass]="{ 'is-invalid': submitted && f.image.errors }" (imageChanged)="imageEvent($event)"></app-image-upload-preview>
      </div>
    </div>
    <div class="row" *ngIf="error">
      <div class="col-xs-12">
        <p class="text-danger">{{error}}</p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xs-12 text-center">
        <button class="btn btn-primary btn-lg" [disabled]="submitting">Save Performer(s) Item <i *ngIf="submitting" class="fa fa-fw fa-circle-o-notch fa-spin"></i></button>
      </div>
    </div>
  </form>

</div>