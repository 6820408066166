import { Component, OnInit } from '@angular/core';
import * as Chartist from 'chartist';
import { AuthService } from 'services/AuthService.service';
import { DashboardService } from 'services/Dashboard.service';
import { Stats } from 'models/stats';
import { ApiRouteService } from '../../services/ApiRouteService';
import { Subscription, timer } from 'rxjs';
import { City } from '../../models/city';
import { Performer } from '../../models/performer';
import { ExplorePlansService } from '../../services/ExplorePlans.service';

declare var $: any;

@Component({
    selector: 'app-dashboard-cmp',
    moduleId: module.id,
    templateUrl: 'dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

    reloadPageTimer: Subscription = null;
    allStats: Stats;
    cities: City[];
    performers: Performer[];

    constructor(
        private api: DashboardService,
        private auth: AuthService,
        private apiRouteService: ApiRouteService,
        private explorePlansService: ExplorePlansService) {

    }

    ngOnInit() {
        const endTimeUnix = (new Date()).toUnix();
        const startTime = new Date();
        startTime.setDate(startTime.getDate() - 1);
        const startTimeUnix = startTime.toUnix();
        this.apiRouteService.routesInitializedSubject.subscribe(() => {
            this.api.getDashboardStats(startTimeUnix, endTimeUnix).subscribe((stats) => {
                console.log('stats ', stats);
                this.allStats = stats;
            }, (error) => {
                console.error(error);
            });
        });

        // reload the page after an hour
        this.reloadPageTimer = timer(1000 * 60 * 60).subscribe(() => {
            window.location.reload();
        });

        this.explorePlansService.getActivePerformers().subscribe(performers => {
            this.performers = performers;
        });

        this.explorePlansService.getCities().subscribe(cities => {
            this.cities = cities;
        });
    }

    getEmailTypes(): string[] {
        if (!this.allStats || !this.allStats.emailStats.sentCounts) {
            return [];
        }
        return Object.keys(this.allStats.emailStats.sentCounts);
    }

    getNotificationTypes(): string[] {
        if (!this.allStats || !this.allStats.notificationStats.notificationCounts) {
            return [];
        }
        return Object.keys(this.allStats.notificationStats.notificationCounts);
    }

    getPerformerFromId(id: string): string {
        if (!this.performers) {
            return '';
        }

        return this.performers.find(p => p.id === id).name;
    }

    getCityFromId(id: string): string {
        if (!this.cities) {
            return '';
        }
        const city = this.cities.find(c => c.id === id);
        return `${city.city}, ${city.state}`;
    }
}
