import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExplorePlansService } from 'services/ExplorePlans.service';
import { City } from 'models/city';
import { GetEventsForPlanDto } from 'models/getEventsForPlanDto';
import { CreateExplorePlan } from 'models/createExplorePlan';
import { Performer } from 'models/performer';
import { Utils } from '../../shared/utils'
import { v4 as uuid } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-planning-modal',
  templateUrl: './add-planning-modal.component.html',
  styleUrls: ['./add-planning-modal.component.scss']
})
export class AddPlanningModalComponent implements OnInit {
  public onPlanCreated: Subject<boolean> = new Subject<boolean>();
  addPlanningForm: FormGroup;
  searching: boolean;
  submitted: boolean;
  submitting: boolean;
  cities: City[];
  selectedCity: City;
  displayedCity: string;
  events: any[];
  error: string;

  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private explorePlansService: ExplorePlansService, public toastr: ToastrService) { }

  ngOnInit() {
    this.explorePlansService.getCities().subscribe(cities => {
      this.cities = cities.map(c => {
        (c as any).label = c.city + ', ' + c.state
        return c
      })
    })

    this.addPlanningForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(4)]],
      expiryDate: [new Date(), Validators.required],
      startDate: [new Date(), Validators.required],
      endDate: [new Date(), Validators.required],
      cityId: [undefined, [Validators.required, Validators.minLength(1)]],
      image: [undefined, Validators.required],
      eventIds: [[], Validators.required]
    });
  }

  imageEvent(image) {
    this.addPlanningForm.patchValue({image})
  }

  get formValue() {
    return this.addPlanningForm.value as CreateExplorePlan;
  }

  get f() {
    return this.addPlanningForm.controls;
  }

  onCityChange(city) {
    this.selectedCity = city;
  }

  getEventsForPlan() {
    const dto: GetEventsForPlanDto = {
      startDate: Utils.formatDate(new Date(this.formValue.startDate)),
      endDate: Utils.formatDate(new Date(this.formValue.endDate)),
      cityId: this.addPlanningForm.value.cityId
    }

    if (!dto.startDate || !dto.endDate || !dto.cityId) {
      return
    }

    this.searching = true
    this.explorePlansService.getEventsForPlan(dto).subscribe(events => {
      this.events = events
      this.searching = false
      this.displayedCity = `${this.selectedCity.city}, ${this.selectedCity.state}`
    }, () => {
      this.events = []
      this.searching = false
      this.displayedCity = `${this.selectedCity.city}, ${this.selectedCity.state}`
    })
  }

  onSubmit() {
    this.submitted = true;

    if (this.addPlanningForm.invalid) {
      return
    }

    this.submitting = true;
    const image = (this.formValue as any).image
    this.explorePlansService.getImageUploadUrl(image.fileName, image.uuid).subscribe(res => {
      this.explorePlansService.putImageUploadUrl(res.url, image.url, image.fileName).subscribe(r => {
        const dto = Object.assign({}, this.formValue)
        dto.id = uuid()
        dto.imageId = image.uuid
        dto.startDate = Utils.formatDate(new Date(this.formValue.startDate))
        dto.endDate = Utils.formatDate(new Date(this.formValue.endDate))
        dto.expiryDate = Math.floor(new Date(this.formValue.expiryDate).setHours(23, 59, 59, 999).valueOf() / 1000)
        dto.action = "ExplorePlanActionPlanning"
        dto.cityIds = [this.addPlanningForm.value.cityId]
    
        this.explorePlansService.createExplorePlan(dto).subscribe(res => {
          this.bsModalRef.hide()
          this.toastr.success('Planning Item Saved', 'Success!')
          this.onPlanCreated.next(true);
        }, err => {
          this.submitting = false
          this.error = err.error ? err.error.message : err.message
        });
      })
    })
  }

}
