export class NotificationDeploymentGroup {
    groupDescription: string;
    users: NotificationUser[];

    constructor(groupDescription, users) {
        this.groupDescription = groupDescription;
        this.users = users;
    }
}

export class NotificationUser {
    id: string;
    isDeviceId: boolean;

    constructor(id, isDeviceId) {
        this.id = id;
        this.isDeviceId = isDeviceId;
    }
}
