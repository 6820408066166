<div class="modal-header">
  <h4 class="modal-title pull-left">Add a Planning Item</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">Cancel</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="addPlanningForm" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="description">Add a title</label>
          <input type="text" class="form-control" id="description" aria-describedby="description" formControlName="description"
            [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
        </div>

        <div class="form-group">
          <label for="expiryDate">Add an expiry date</label>
          <div class="input-group">
            <input type="text" placeholder="Datepicker" class="form-control" formControlName="expiryDate" bsDatepicker
              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
              [ngClass]="{ 'is-invalid': submitted && f.expiryDate.errors }">
            <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
          </div>
        </div>

        <div class="row" style="margin-bottom: -15px;">
          <div class="col-xs-6">
              <label for="startDate">Add a Start date</label>
              <div class="input-group">
                <input type="text" placeholder="Datepicker" class="form-control" formControlName="startDate" bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                  [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }">
                <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
              </div>
            </div>
    
            <div class="col-xs-6">
              <label for="endDate">Add an End date</label>
              <div class="input-group">
                <input type="text" placeholder="Datepicker" class="form-control" formControlName="endDate" bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                  [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }">
                <span class="input-group-addon"><i class="fa fa-fw fa-calendar"></i></span>
              </div>
            </div>
        </div>

        <div class="form-group">
          <label for="cityIds">Add City ID</label>
          <div class="row">
            <div class="col-md-9 col-xs-8">
              <ng-select #select [virtualScroll]="true" [items]="cities" bindLabel="label" bindValue="id" [loading]="!cities" (change)="onCityChange($event)"
              formControlName="cityId" [ngClass]="{ 'is-invalid': submitted && f.cityId.errors }">
              </ng-select>
            </div>
            <div class="col-md-3 col-xs-4">
              <button class="btn btn-primary btn-md" (click)="getEventsForPlan(); $event.preventDefault()">Search</button>
            </div>
          </div>
          
        </div>

        <div class="form-group">
          <label for="eventIds">Choose events from {{displayedCity || 'N/A'}}</label>
          <ng-select #select [virtualScroll]="true" [items]="events" bindLabel="eventName" bindValue="id" [closeOnSelect]="false" [loading]="searching" [multiple]="true"
          formControlName="eventIds" [ngClass]="{ 'is-invalid': submitted && f.eventIds.errors }">
            <ng-template ng-option-tmp let-item="item">
              {{item.eventName}}<br/>
              <span class="text-muted">&nbsp;{{item.eventDateString | date:'MMM d, y, h:mm a'}}</span>
            </ng-template>
          </ng-select>
        </div>
        
      </div>
      <div class="col-md-6">
        <label for="file">Add an image</label><br/>
        <app-image-upload-preview [componentClass]="{ 'is-invalid': submitted && f.image.errors }" (imageChanged)="imageEvent($event)"></app-image-upload-preview>
      </div>
    </div>
    <div class="row" *ngIf="error">
      <div class="col-xs-12">
        <p class="text-danger">{{error}}</p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xs-12 text-center">
        <button class="btn btn-primary btn-lg" [disabled]="submitting || !displayedCity">Save Planning Item <i *ngIf="submitting" class="fa fa-fw fa-circle-o-notch fa-spin"></i></button>
      </div>
    </div>
  </form>

</div>