export class TimeSeriesRequest {
    serviceNames: string[];
    endTime: number;
    startTime: number;
    timePeriod: number;
    maxNumPages: number;
}


export interface ResponseTimeHistogram {
    Count: number;
    Value: number;
}

export interface ErrorStatistics {
    OtherCount: number;
    ThrottleCount: number;
    TotalCount: number;
}

export interface FaultStatistics {
    OtherCount: number;
    TotalCount: number;
}

export interface TimeSeriesData {
    ResponseTimeHistogram: ResponseTimeHistogram[];
    ErrorStatistics: ErrorStatistics;
    FaultStatistics: FaultStatistics;
    OkCount: number;
    TotalCount: number;
    TotalResponseTime: number;
    AverageResponseTime: number;
    Timestamp: Date;
}

