import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MapService } from '../../services/map.service';
import MarkerClusterer from '@google/markerclustererplus';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit, OnInit {

  @ViewChild('mapContainer') gmap: ElementRef;

  map: google.maps.Map;
  lat = 39.5;
  lng = -98.35;
  coordinates = new google.maps.LatLng(this.lat, this.lng);
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 4,
    streetViewControl: false,
  };

  allCities = [];
  markers = [];
  allSuburbs: any[];
  suburbs = [];
  masterCities = [];
  userLocations = [];
  searchedCities = [];
  unfilteredCities = [];
  markerCluster: any;
  overLappingCities = [];

  date = new FormControl(new Date());
  //serializedDate = new FormControl((new Date()).toISOString());

  constructor(private mapService: MapService) { }

  ngOnInit() {
    this.getCities();
    this.getAllSuburbs();
    this.getUserLocations();
    this.getSearchedCities();
    // need to divide date.now by 1000 to get the time in the right format
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mapInitializer();
    }, 2000);
  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement,
      this.mapOptions);
    this.markerCluster = new MarkerClusterer(this.map, this.markers, {
      imagePath: '../../assets/img/mapClusters/',
      maxZoom: 9
    });
    this.markerCluster.setIgnoreHidden(true);
  }

  getCities() {
    this.mapService.getCities().subscribe(
      (cities) => {
        this.unfilteredCities = cities;
        cities.forEach(city => {
          if (city.state != '' && city.state != '0') {
            this.allCities.push(city);
          }
        });
        //console.log(this.allCities);
        this.getMasterCities();
      }, (error) => {
        console.log(error);
      }
    );
  }

  getAllSuburbs() {
    this.mapService.getAllSuburbs().subscribe(
      (suburbs) => {
        this.allSuburbs = suburbs;
        //console.log(suburbs);
      }, (error) => {
        console.log(error);
      }
    );
  }

  getUserLocations() {
    const lastWeek = (Date.now()/1000) - 604800000;
    this.mapService.getUserLocations(lastWeek).subscribe(
      (userLocations) => {
        this.userLocations = userLocations;
      }, (error) => {
        console.log(error);
      }
    );
  }

  getSearchedCities() {
    const lastWeek = (Date.now()/1000) - 604800000;
    this.mapService.getCitySearches(lastWeek).subscribe(
      (cities) => {
        this.searchedCities = cities;
      }, (error) => {
        console.log(error);
      }
    );
  }

  getMasterCities() {
    this.allSuburbs.forEach(suburb => {
      if (suburb.masterCity) {
        this.suburbs.push(suburb);
      }
    });
    this.suburbs.forEach(suburb => {
      this.allCities.forEach(city => {
        if (suburb.cityId === city.id) {
          this.masterCities.push(city);
          let index = this.allCities.indexOf(city);
          if (index > -1) {
            this.allCities.splice(index, 1);
          }
        }
      })
    });
    //console.log(this.masterCities);
    this.suburbs = [];
    this.filterSuburbs();
  }

  filterSuburbs() {
    this.allSuburbs.forEach(suburb => {
      this.allCities.forEach(city => {
        if (city.id === suburb.cityId) {
          this.suburbs.push(city);
          let index = this.allCities.indexOf(city);
          if (index > -1) {
            this.allCities.splice(index, 1);
          }
        } 
      });
    });

    const array = this.masterCities.concat(this.suburbs);
    array.forEach(city => {
      this.allCities.forEach(allCity => {
        if ((city.lng === allCity.lng) && (city.lat === allCity.lat)) {
          this.overLappingCities.push(city);
          this.overLappingCities.push(allCity);
        }
      })
    })
    this.generateMarkers();
  }

  generateMarkers() {
    // generating markers for cities 
    this.allCities.forEach(city => {
      const coordinates = new google.maps.LatLng(city.lat, city.lng);
      const marker = new google.maps.Marker({
        position: coordinates,
        map: this.map,
        title: city.city + ', ' + city.state + '<br>' + city.id,
        cursor: 'city'
      });
      const infoWindow = new google.maps.InfoWindow({
        content: marker.getTitle()
      });
      marker.addListener("click", () => {
        infoWindow.open(marker.getMap(), marker);
      });
      this.markers.push(marker);
    });

    // generating markers for Master Cities
    this.masterCities.forEach(city => {
      const coordinates = new google.maps.LatLng(city.lat, city.lng);
      const marker = new google.maps.Marker({
        position: coordinates,
        map: this.map,
        title: city.city + ', ' + city.state + '<br>' + city.id,
        icon: "../../assets/img/masterCityPin.png",
        cursor: 'masterCity'
      });
      const infoWindow = new google.maps.InfoWindow({
        content: marker.getTitle()
      });
      marker.addListener("click", () => {
        infoWindow.open(marker.getMap(), marker);
      });
      this.markers.push(marker);
    });

    //generating markers for suburbs 
    this.suburbs.forEach(suburb => {
      const coordinates = new google.maps.LatLng(suburb.lat, suburb.lng);
      const marker = new google.maps.Marker({
        position: coordinates,
        map: this.map,
        title: suburb.city + ', ' + suburb.state + '<br>' + suburb.id,
        icon: "../../assets/img/suburbPin.png",
        cursor: 'suburb'
      });
      const infoWindow = new google.maps.InfoWindow({
        content: marker.getTitle()
      });
      marker.addListener("click", () => {
        infoWindow.open(marker.getMap(), marker);
      });
      this.markers.push(marker);
    });

    // generating markers for users
    this.userLocations.forEach(user => {
      const coordinates = new google.maps.LatLng(user.coordinates[0], user.coordinates[1]);
      const marker = new google.maps.Marker({
        position: coordinates,
        map: this.map,
        icon: "../../assets/img/personPin.png",
        title: user.id,
        cursor: 'user'
      });
      const infoWindow = new google.maps.InfoWindow({
        content: marker.getTitle()
      });
      marker.addListener("click", () => {
        infoWindow.open(marker.getMap(), marker);
      });
      this.markers.push(marker);
    });

    // generating markers for searched cities
    this.searchedCities.forEach(city => {
      this.unfilteredCities.forEach(unfilteredCity => {
        if (city.cityIds[0] === unfilteredCity.id) {
          const coordinates = new google.maps.LatLng(unfilteredCity.lat, unfilteredCity.lng);
          const marker = new google.maps.Marker({
            position: coordinates,
            map: this.map,
            icon: "../../assets/img/searchedCityPin.png",
            title: unfilteredCity.city + ', ' + unfilteredCity.state + '<br>' + unfilteredCity.id,
            cursor: 'searchedCity'
          });
          const infoWindow = new google.maps.InfoWindow({
            content: marker.getTitle()
          });
          marker.addListener("click", () => {
            infoWindow.open(marker.getMap(), marker);
          });
          this.markers.push(marker);
        }
      })
    });

    this.markers.forEach(marker => {
      marker.setMap(this.map);
    });
  }

  toggleMarker(markerType: string) {
    this.markers.forEach(marker => {
      if (marker.cursor === markerType) {
        if (!marker.getVisible()) {
          marker.setVisible(true);
        } else {
          marker.setVisible(false);
        }
      }
    });
    this.markerCluster.repaint();
  }

  // getUsers() {
  //   const date = new Date(this.date.value);
  //   const timeStamp = (date.getTime()/1000);
  //   this.userLocations = [];
  //   this.mapService.getUserLocations(timeStamp).subscribe(
  //     (userLocations) => {
  //       this.userLocations = userLocations;
  //       console.log(userLocations);
  //     }, (error) => {
  //       console.log(error);
  //     }
  //   );
  //   this.markers = [];
  //   this.mapInitializer();
  //   this.generateMarkers();
  // }

}
