import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../../services/NotificationService.service';
import { EventTicketPrices, Price } from '../../../models/EventTicketPrices';
import { Chart } from 'chart.js';

@Component({
    selector: 'app-price-drop-event',
    templateUrl: './price-drop-event.component.html',
    styleUrls: ['./price-drop-event.component.scss']
})
export class PriceDropEventComponent implements OnInit {

    eventTicketPrices: EventTicketPrices;
    cityName: string;

    @ViewChild('priceChart') priceChart: ElementRef;

    constructor(public bsModalRef: BsModalRef,
                public toastr: ToastrService,
                private notificationService: NotificationService) {
    }

    ngOnInit() {
        console.log(this.eventTicketPrices);
        this.createPriceChart();
    }

    createPriceChart() {
        const ctx = this.priceChart.nativeElement.getContext('2d');
        const priceDataChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: this.getSortedDates(),
                datasets: [{
                    label: 'Min Prices ($)',
                    data: this.getSortedPrices(),
                    borderWidth: 1,
                    borderColor: '#FFCE00',
                    yAxisID: 'prices',
                    fill: true,
                    backgroundColor: '#FFCE0050',
                    fillOpacity: 0.50
                }, {
                    label: 'Ticket Count',
                    data: this.getSortedTicketCounts(),
                    borderWidth: 1,
                    borderColor: '#333333',
                    yAxisID: 'tickets',
                    fill: false
                }]
            },
            options: {
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'day'
                        }
                    }],
                    yAxes: [{
                        id: 'prices',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            beginAtZero: true
                        }
                    }, {
                        id: 'tickets',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    getSortedPrices(): number[] {
        return this.eventTicketPrices.prices.sort(i => i.timestamp).map(p => p.minPrice);
    }

    getSortedTicketCounts(): number[] {
        return this.eventTicketPrices.prices.sort(i => i.timestamp).map(p => p.ticketCount);
    }

    getSortedDateLabels(): string[] {
        return this.eventTicketPrices.prices.sort(i => i.timestamp).map(i => {
                const d = new Date(i.timestamp * 1000);
                return d.toDateString();
            }
        );
    }

    getSortedDates(): Date[] {
        return this.eventTicketPrices.prices.sort(i => i.timestamp).map(i => {
                return new Date(i.timestamp * 1000);
            }
        );
    }
}

