import { Injectable } from '@angular/core';
import { AbstractService } from './AbstractService.service';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs-compat/add/operator/map';
import { Performer } from '../models/performer';
import { ScheduleNotificationsRequest } from '../models/ScheduleNotificationRequest';
import { NotificationSummary } from '../models/NotificationSummary';
import { NotificationUser } from '../models/notificationDeploymentGroup';
import { EventTicketPrices } from '../models/EventTicketPrices';
import { DynamicLink } from '../models/DynamicLink';
import { HttpClient } from '@angular/common/http';
import { ApiRouteService } from './ApiRouteService';

@Injectable()
export class NotificationService extends AbstractService {

    protected baseUrl = environment.serviceRouterUrl;

    constructor(protected http: HttpClient, private apiRouteService: ApiRouteService) {
        super(http);
    }

    public getUsersForLocation(lat: number, lng: number, radius: number, preferenceId: string,
                               lastTimestamp: number): Observable<NotificationUser[]> {
        const url = this.apiRouteService.getUrl('dashboard-notification', 'users-for-location');
        const params = {
            'lat': lat.toString(),
            'lng': lng.toString(),
            'preferenceId': (preferenceId || ''),
            'lastTimestamp': (lastTimestamp || '').toString(),
            'radius': radius.toString(),
        };
        return this.http
            .get<any>(url, {params: params, headers: this.headers()})
            .map(data => this.formatResults(data));
    }

    public getUsersWithPreference(preferenceId: string): Observable<NotificationUser[]> {
        const url = this.apiRouteService.getUrl('dashboard-notification', 'users-for-preference');
        const params = {
            'preferenceId': preferenceId,
        };

        return this.http
            .get<any>(url, {params: params, headers: this.headers()})
            .map(data => this.formatResults(data));
    }

    public getUsersWithHomeCity(homeCityId: string, preferenceId): Observable<NotificationUser[]> {
        const url = this.apiRouteService.getUrl('dashboard-notification', 'users-for-home-city');
        const params = {
            'preferenceId': (preferenceId || ''),
            'homeCityId': homeCityId,
        };

        return this.http
            .get<any>(url, {params: params, headers: this.headers()})
            .map(data => this.formatResults(data));
    }

    public getUsersWithLastSessionDates(afterDate: number, beforeDate: number, getDeviceIds: boolean): Observable<NotificationUser[]> {
        const url = this.apiRouteService.getUrl('dashboard-notification', 'users-for-last-session');
        const deviceUrl = this.apiRouteService.getUrl('dashboard-notification', 'devices-for-last-session');
        const params = {
            'BeforeTimestamp': beforeDate.toString(),
            'AfterTimestamp': afterDate.toString(),
        };

        if (!getDeviceIds) {
            return this.http
                .get<any>(url, {params: params, headers: this.headers()})
                .map(data => this.formatResults(data));
        } else {
            return this.http
                .get<any>(deviceUrl, {params: params, headers: this.headers()})
                .map(data => this.formatResults(data));
        }
    }

    public getPerformersRecentlyUpdated(updatedSinceDate: number): Observable<Performer[]> {
        const url = this.apiRouteService.getUrl('dashboard-notification', 'recently-added-event-performers');
        const params = {
            'timestamp': updatedSinceDate.toString(),
        };

        return this.http.get<Performer[]>(url, {params: params, headers: this.headers()});
    }

    public scheduleNotifications(request: ScheduleNotificationsRequest): Observable<any[]> {
        const url = this.apiRouteService.getUrl('dashboard-notification', 'schedule-notifications');
        return this.http.post<any[]>(url, request, {headers: this.headers()});
    }

    public deleteNotification(summary: NotificationSummary): Observable<string> {
        console.log(summary);
        const url = this.apiRouteService.getUrl('dashboard-notification', 'delete-notifications');
        // @ts-ignore
        return this.http.request<string>('DELETE', url, {
            body: summary, responseType: 'text' as 'json',
            headers: this.headers()
        });
    }


    public getNotificationSummaries(): Observable<NotificationSummary[]> {
        const url = this.apiRouteService.getUrl('dashboard-notification', 'notification-summaries');
        return this.http.get<NotificationSummary[]>(url, {headers: this.headers()});
    }

    // tslint:disable-next-line:max-line-length
    public getPriceDropEvents(cityIds: string, comparisonValue: number, type: string, afterTimestamp: number): Observable<EventTicketPrices[]> {
        const url = this.apiRouteService.getUrl('dashboard-notification', 'price-drop-events');
        const params = {
            'cityIds': cityIds,
            'type': type,
            'comparisonValue': comparisonValue.toString(),
            'afterTimestamp': afterTimestamp.toString(),
        };
        return this.http
            .get<EventTicketPrices[]>(url, {params: params, headers: this.headers()});
    }

    private formatResults(data: any[]): NotificationUser[] {
        let results = [];
        if (data['userIds']) {
            results = results.concat(data['userIds'].map(u => new NotificationUser(u, false)));
        }
        if (data['deviceIds']) {
            results = results.concat(data['deviceIds'].map(u => new NotificationUser(u, true)));
        }
        return results.flat();
    }

    public generateDynamicLink(request: object): Observable<DynamicLink> {
        const url = this.apiRouteService.getUrl('dashboard-notification', 'get-dynamic-link');
        return this.http.post<DynamicLink>(url, request, {headers: this.headers()});
    }
}
