import { AfterViewInit, Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ImagesService } from '../../services/Images.service';
import { ExplorePlansService } from '../../services/ExplorePlans.service';
import { City } from '../../models/city';
import { Venue } from '../../models/venue';
import { Category } from '../../models/category';
import { EventType } from '../../models/eventType';
import { Performer } from '../../models/performer';
import { MatInput, MatPaginator, MatTable, MatTableDataSource } from '@angular/material';
import { delay, tap } from 'rxjs/operators';
import { forEach } from '@angular/router/src/utils/collection';
import { KrugoImage, KrugoImageUrl } from '../../models/KrugoImage';
import { ImageUploadPreviewComponent } from '../explore-plans/image-upload-preview/image-upload-preview.component';
import { Observable, of } from 'rxjs';
import { v4 as uuid } from 'uuid';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/delay';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Image } from '../../models/image';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-images',
    templateUrl: './images.component.html',
    styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit, AfterViewInit {

    venues: Venue[] = [];
    eventTypes: EventType[] = [];
    cities: City[] = [];
    categories: Category[] = [];
    activePerformers: Performer[] = [];
    allPerformers: Performer[] = [];
    allListItems: ImageListItem[];
    listItems: ImageListItem[];
    tableDataSource = new MatTableDataSource<ImageListItem>();
    imageUrl = '';
    searchText = '';
    selectedId = '';
    selectedImageType = '';
    selectedImages: KrugoImage[] = [];
    showLoadingSpinner = false;
    image: Image;
    lastId = '';

    bsModalRef: BsModalRef;
    pageNumber: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(ImageUploadPreviewComponent) newImagePreview: ImageUploadPreviewComponent;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private modalService: BsModalService,
                private imagesService: ImagesService,
                private explorePlansService: ExplorePlansService,
                public toastr: ToastrService) {
        this.imagesService.getVenues().subscribe((v) => this.venues = v);
        this.imagesService.getEventTypes().subscribe((e) => this.eventTypes = e);
        this.explorePlansService.getCities().subscribe((c) => this.cities = c);
        this.explorePlansService.getCategories().subscribe((c) => this.categories = c);
        this.explorePlansService.getActivePerformers().subscribe((p) => this.activePerformers = p);
        this.imagesService.getAllPerformers().subscribe((ap) => this.allPerformers = ap);
    }

    ngOnInit() {
        this.tableDataSource.sort = this.sort;
    }

    ngAfterViewInit() {
        this.paginator.page
            .pipe(
                tap(() => this.loadCurrentPage())
            )
            .subscribe();

        this.tableDataSource.paginator = this.paginator;
    }

    goToPage() {
        this.paginator.pageIndex = this.pageNumber;
        this.paginator.page.next({
            pageIndex: this.pageNumber,
            pageSize: this.paginator.pageSize,
            length: this.paginator.length
        });
    }

    showError(e: HttpErrorResponse) {
        console.log(e);
        this.toastr.error(e.status + ' ' + e.statusText, 'An Error Occurred');
    }

    getSelectedImageTypeTitle(): string {
        if (!this.selectedImageType || this.selectedImageType.length === 0) {
            return 'Select Image Type';
        } else if (this.selectedImageType === 'ImageType_City') {
            return 'City Images';
        } else if (this.selectedImageType === 'ImageType_Venue') {
            return 'Venue Images';
        } else if (this.selectedImageType === 'ImageType_Category') {
            return 'Category Images';
        } else if (this.selectedImageType === 'ImageType_Performer') {
            return 'Active Performer Images';
        } else if (this.selectedImageType === 'ImageType_AllPerformer') {
            return 'All Performer Images';
        } else if (this.selectedImageType === 'ImageType_EventType') {
            return 'Event Type Images';
        }
    }

    addImageFromUrl() {
        const url = this.imageUrl;
        this.showLoadingSpinner = true;
        this.imagesService.getImage(url).subscribe((blob) => {
            let fileExt = '.jpeg';
            if (blob.type === 'image/png') {
                fileExt = '.png';
            }
            if (blob.type === 'image/jpg') {
                fileExt = '.jpg';
            }
            const file = new File([blob], uuid() + fileExt);
            this.imagesService.getImageUploadUrl(file.name, this.selectedId, this.selectedImageType).subscribe((data) => {
                this.imagesService.putImageBlob(data.url, blob).subscribe(() => {
                    if (this.newImagePreview) {
                        this.newImagePreview.reset();
                    }
                    this.imageUrl = '';
                    setTimeout(() => this.refreshCurrentSelectedImages().subscribe(() => {
                        this.showLoadingSpinner = false;
                    }), 3000);
                }, (e) => {
                    this.showError(e);
                    this.showLoadingSpinner = false;
                });
            }, (e) => {
                this.showError(e);
                this.showLoadingSpinner = false;
            });
        }, (e) => {
            this.showError(e);
            this.showLoadingSpinner = false;
        });
    }

    handleImageAdded(newImage: Image) {
        if (!newImage || !newImage.fileName) {
            return;
        }

        console.log(newImage);
        this.image = newImage;
        this.showLoadingSpinner = true;
        this.imagesService.getImageUploadUrl(this.image.fileName, this.selectedId, this.selectedImageType).subscribe((data) => {
            this.imagesService.putImageUploadUrl(data.url, this.image.url, this.image.fileName).subscribe((d) => {
                if (this.newImagePreview) {
                    this.newImagePreview.reset();
                }
                this.imageUrl = '';
                setTimeout(() => this.refreshCurrentSelectedImages().subscribe(() => {
                    this.showLoadingSpinner = false;
                }), 1000);
            }, (e) => {
                this.showError(e);
                this.showLoadingSpinner = false;
            });
        }, (e) => {
            this.showError(e);
            this.showLoadingSpinner = false;
        });
    }

    deleteImage(imageToDelete: KrugoImage) {
        this.showLoadingSpinner = true;
        this.imagesService.deleteImage(imageToDelete.id, imageToDelete.md5Hash).subscribe((res) => {
            console.log('Image Deleted');
            setTimeout(() => this.refreshCurrentSelectedImages().subscribe(() => {
                this.showLoadingSpinner = false;
            }), 1000);
        }, (e) => {
            this.showError(e);
            this.showLoadingSpinner = false;
        });
    }

    public imageTypeSelected(type: string) {
        let unsortedItems = [];
        if (type === 'ImageType_City') {
            unsortedItems = this.cities.map((c) => {
                const stateString = c.state.trim() ? ', ' + c.state : '';
                return new ImageListItem(c.city + stateString, c.id);
            });
        } else if (type === 'ImageType_Venue') {
            unsortedItems = this.venues.map((v) => {
                const city = this.cities.find((c) => c.id === v.cityId);
                const cityName = city ? city.city : '';
                const stateString = city && city.state.trim() ? ', ' + city.state : '';
                return new ImageListItem(`${v.name} (${cityName}${stateString})`, v.id);
            });
        } else if (type === 'ImageType_Category') {
            unsortedItems = this.categories.map((c) => new ImageListItem(c.name, c.id));
        } else if (type === 'ImageType_Performer') {
            unsortedItems = this.activePerformers.map((p) => new ImageListItem(p.name, p.id));
        } else if (type === 'ImageType_AllPerformer') {
            unsortedItems = this.allPerformers.map((ap) => new ImageListItem(ap.name, ap.id));
            type = 'ImageType_Performer';
        } else if (type === 'ImageType_EventType') {
            unsortedItems = this.eventTypes.map((e) => new ImageListItem(e.name, e.id));
        }
        this.selectedImageType = type;
        this.allListItems = unsortedItems.sort((a, b) => a.name.localeCompare(b.name));
        this.listItems = this.allListItems;
        this.paginator.pageIndex = 0;
        this.selectedId = '';
        this.searchText = '';
        this.loadCurrentPage();
    }

    loadCurrentPage() {
        this.tableDataSource.data = this.listItems;
        const imageIds = this.tableDataSource._pageData(this.tableDataSource.data).map((i) => i.id);
        this.tableDataSource._pageData(this.tableDataSource.data).forEach((item) => item.imageCount = -1);
        this.imagesService.getImages(imageIds).subscribe((data) => {
            this.tableDataSource.data.forEach((item, index) => {
                const images = data[item.id];
                if (images) {
                    this.tableDataSource.data[index].images = images;
                    this.tableDataSource.data[index].imageCount = images.length;
                } else {
                    this.tableDataSource.data[index].imageCount = 0;
                }
            });
            this.tableDataSource._updateChangeSubscription();
        });
    }

    refreshButtonTapped() {
        this.showLoadingSpinner = true;
        setTimeout(() => this.refreshCurrentSelectedImages().subscribe(() => {
            this.showLoadingSpinner = false;
        }), 1000);
    }

    refreshCurrentSelectedImages(): Observable<boolean> {
        return new Observable<boolean>((obs) => {
            this.imagesService.getImages([this.selectedId]).subscribe((data) => {
                const index = this.tableDataSource.data.findIndex((item) => item.id === this.selectedId);
                const images = data[this.selectedId];
                if (images) {
                    this.tableDataSource.data[index].images = images;
                    this.tableDataSource.data[index].imageCount = images.length;
                } else {
                    this.tableDataSource.data[index].imageCount = 0;
                    this.tableDataSource.data[index].images = [];
                }
                this.selectedImages = this.tableDataSource.data[index].images;
                obs.next(true);
                this.tableDataSource._updateChangeSubscription();
            }, (e) => {
                this.showError(e);
                obs.next(false);
            });
        });
    }

    searchTextChanged() {
        this.listItems = this.allListItems.filter((item) => item.name.toUpperCase().includes(this.searchText.toUpperCase()));
        this.paginator.pageIndex = 0;
        this.loadCurrentPage();
    }

    selectRow(row: ImageListItem) {
        this.selectedId = row.id;
        this.selectedImages = row.images;
    }

    getSmallImageUrl(imageUrls: KrugoImageUrl[]): string {
        return imageUrls.find((i) => i.size === 'medium').url;
    }

    getThumbImageUrl(imageUrls: KrugoImageUrl[]): string {
        return imageUrls.find((i) => i.size === 'thumb').url;
    }

    getSelectedListItem(): ImageListItem {
        return this.tableDataSource.data.find((x) => x.id === this.selectedId);
    }
}

class ImageListItem {
    public id: string;
    public name: string;
    public imageCount = -1;
    public images: KrugoImage[] = [];

    constructor(name: string, id: string) {
        this.name = name;
        this.id = id;
    }
}

