import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CreateExplorePlan } from 'models/createExplorePlan';
import { Performer } from 'models/performer';
import { ExplorePlansService } from 'services/ExplorePlans.service';
import { v4 as uuid } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-performer-modal',
  templateUrl: './add-performer-modal.component.html',
  styleUrls: ['./add-performer-modal.component.scss']
})
export class AddPerformerModalComponent implements OnInit {
  public onPlanCreated: Subject<boolean> = new Subject<boolean>();
  addPerformerForm: FormGroup;
  submitted: boolean;
  submitting: boolean;
  performers: Performer[];
  selectedPerfomers: Performer[];
  error: string;

  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private explorePlansService: ExplorePlansService, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.explorePlansService.getActivePerformers().subscribe(performers => {
      this.performers = performers
    })

    this.addPerformerForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(4)]],
      expiryDate: [new Date(), Validators.required],
      performerIds: [[], [Validators.required, Validators.minLength(1)]],
      image: [undefined, Validators.required]
    });
  }

  imageEvent(image) {
    this.addPerformerForm.patchValue({image})
  }

  get formValue() {
    return this.addPerformerForm.value as CreateExplorePlan;
  }

  get f() {
    return this.addPerformerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.addPerformerForm.invalid) {
      return
    }

    this.submitting = true;
    const image = (this.formValue as any).image
    this.explorePlansService.getImageUploadUrl(image.fileName, image.uuid).subscribe(res => {
      this.explorePlansService.putImageUploadUrl(res.url, image.url, image.fileName).subscribe(r => {
        const dto = Object.assign({}, this.formValue)
        dto.id = uuid()
        dto.imageId = image.uuid
        dto.expiryDate = Math.floor(new Date(this.formValue.expiryDate).setHours(23, 59, 59, 999).valueOf() / 1000)
        dto.action = "ExplorePlanActionPerformerSearch"
    
        this.explorePlansService.createExplorePlan(dto).subscribe(res => {
          this.bsModalRef.hide()
          this.toastr.success('Performer(s) Item Saved', 'Success!')
          this.onPlanCreated.next(true);
        }, err => {
          this.submitting = false
          this.error = err.error ? err.error.message : err.message
        });
      })
    })
  }

}
