import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { AbstractService } from './AbstractService.service';
import { ApiRouteService } from './ApiRouteService';




@Injectable({
    providedIn: 'root'
  })
export class MapService extends AbstractService{

    constructor(protected http: HttpClient, private apiRouteService: ApiRouteService) {
        super(http);
    }

    public getCities(): Observable<any> {
        const url = this.apiRouteService.getUrl('dashboard', 'get-cities');
        return this.http.get<any>(url, {headers:this.cityHeaders()});
    }

    public getAllSuburbs(): Observable<any> {
        const url = this.apiRouteService.getUrl('dashboard', 'get-suburbs');
        return this.http.get<any>(url, {headers:this.cityHeaders()});
    }

    // TODO -> make the timestamp changeable 
    public getUserLocations(timestamp): Observable<any> {
        const url = this.apiRouteService.getUrl('dashboard-map', 'get-user-locations');
        const params = timestamp;
        return this.http.get<any>(url, {params: params, headers:this.cityHeaders()});
    }


    // TODO -> make the timestamp changeable 
    public getCitySearches(timestamp): Observable<any> {
        const url = this.apiRouteService.getUrl('dashboard-map', 'get-city-searches');
        const params = timestamp;
        return this.http.get<any>(url, {params: params, headers:this.cityHeaders()});
    }


}
