import { Injectable } from '@angular/core';
import { AbstractService } from './AbstractService.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Buffer } from 'buffer';
import { environment } from '../environments/environment';
import 'rxjs/add/operator/map';
import { Promotion } from '../models/promotion';
import { ApiRouteService } from './ApiRouteService';

@Injectable()
export class PromotionService extends AbstractService {

    protected baseUrl = environment.serviceRouterUrl;

    constructor(protected http: HttpClient, private apiRouteService: ApiRouteService) {
        super(http);
    }

    public getImageUploadUrl(fileName: string, imageId: string): Observable<any> {
        const url = this.apiRouteService.getUrl('dashboard-promotion', 'get-promotion-upload-url');
        return this.http.get<any>(
            url,
            {
                headers: this.headers(),
                params: {FileName: fileName.toLowerCase(), ImageId: imageId}
            });
    }

    public putImageUploadUrl(url: string, file: string, fileName: string) {
        fileName = fileName.toLowerCase();
        let type = '';
        switch (fileName.split('.').pop()) {
            case 'jpg':
            case 'jpeg':
                type = 'image/jpeg';
                break;
            case 'png':
                type = 'image/png';
                break;
        }

        const buff = new Buffer(file.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        const headers = new HttpHeaders({'Content-Type': type, 'Content-Encoding': 'base64'});
        const blob = new Blob([new Uint8Array(buff)]);
        return this.http.put<any>(url, blob, {headers});
    }

    public getPromotions(activeOnly: boolean): Observable<Promotion[]> {
        const params = new HttpParams().set('ActiveOnly', activeOnly.toString());
        const url = this.apiRouteService.getUrl('dashboard-promotion', 'get-promotions');
        return this.http.get<Promotion[]>(url, {headers: this.headers(), params: params});
    }

    public createPromotion(newPromotion: Promotion): Observable<Promotion> {
        const url = this.apiRouteService.getUrl('dashboard-promotion', 'create-promotion');
        return this.http.post<Promotion>(url, newPromotion, {headers: this.headers()});
    }
}
