<div class="paper-wrapper">
    <div class="sidebar" data-background-color="white" data-active-color="warning">
        <app-sidebar-cmp></app-sidebar-cmp>
    </div>
    <div class="main-panel">
        <navbar-cmp></navbar-cmp>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

